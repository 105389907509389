import useLivlyUser from "@/context/UserProvider";
import { Feedback, FeedbackRequest, ReviewLocation } from "@/types/Maintenance";
import { Task, User } from "@/types/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { RatingIcon } from "./RatingIcon";
import { Modal } from "./Dialog";
import { textAreaClass } from "./Form";
import { Button } from "./Button";
import axios from "axios";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import ThankYou from "./FeedbackThankYou";

const RESIDENT_SENTIMENT_TASK_KEY = "task-sentiment-visible";

const variants = {
  open: { opacity: 1, scale: 1, height: "auto" },
  closed: { opacity: 0, scale: 0, height: 0 },
};

export default function ResidentSentimentTask({
  task,
}: {
  task: Task;
  leaseId: number;
}) {
  const feedbackId = task.metaData?.feedbackId as number;
  const {
    reviewLocations,
    showThankYou,
    isVisible,
    isModalOpen,
    feedback,
    onSetFeedback,
    onSkip,
    onSubmit,
    setIsVisible,
    user,
    setIsModalOpen,
    isLoading,
    onCloseThankYou,
  } = usePostResidentSentiment(feedbackId);
  const { title } = getFeedbackTitle(task, user);

  return (
    <div>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, scale: 0, height: 0 }}
          animate={isVisible ? "open" : "closed"}
          variants={variants}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <div className="p-4 bg-white rounded-xl shadow-md">
            <div className="flex justify-end">
              <button
                className="text-gray-400"
                onClick={() => {
                  setIsVisible(false);
                  localStorage.setItem(RESIDENT_SENTIMENT_TASK_KEY, "false");
                }}
              >
                <FontAwesomeIcon icon="times" />
              </button>
            </div>
            <div className="mt-0">
              <div className="flex flex-col items-center space-y-2">
                <h2 className="font-medium text-center">{title}</h2>
                <p className="text-sm text-gray-500">
                  Rate your feelings below
                </p>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="grid grid-cols-4 gap-8 mt-4">
                {[0, 1, 2, 3].map((rating) => (
                  <label
                    className="flex items-center justify-center"
                    key={rating}
                    onClick={() => {
                      onSetFeedback({ rating });
                      setIsModalOpen(true);
                    }}
                  >
                    <input
                      id="rating"
                      name="rating"
                      type="radio"
                      value={rating.toString()}
                      className="hidden w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                    <RatingIcon rating={rating} isSelected size="3x" />
                    <span
                      className="hidden ml-4"
                      aria-label={rating.toString()}
                    >
                      {rating}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
      <ResidentSentimentModal
        key={feedbackId}
        isLoading={isLoading}
        isModalOpen={isModalOpen}
        feedback={feedback}
        onSetFeedback={onSetFeedback}
        onSkip={onSkip}
        onSubmit={onSubmit}
        showThankYou={showThankYou}
        reviewLocations={reviewLocations}
        onClose={() => setIsModalOpen(false)}
        onCloseThankYou={onCloseThankYou}
        feedbackType={task.metaData?.feedbackType as FeedbackType}
      />
    </div>
  );
}

type FeedbackType = "MaintenanceAIChat" | "Default";

function getFeedbackTitle(task: Task, user: User) {
  let title = "";
  let subtitle = "";

  const feedbackType = task.metaData?.feedbackType as FeedbackType;

  switch (feedbackType) {
    case "MaintenanceAIChat":
      title = "Thank you for using Livly’s Assistant";
      subtitle = "";
      break;
    default:
      title = `How is your experience living in ${user.buildingName}?`;
      break;
  }

  return { title, subtitle };
}

export function ResidentSentimentModal({
  isModalOpen,
  onClose,
  onSetFeedback,
  onSubmit,
  onSkip,
  isLoading,
  feedback,
  showThankYou,
  reviewLocations,
  onCloseThankYou,
  feedbackType,
}: {
  isModalOpen: boolean;
  onClose: () => void;
  onSetFeedback: (feedback: Partial<FeedbackRequest>) => void;
  onSkip: () => void;
  onSubmit: () => void;
  feedback: FeedbackRequest | null;
  showThankYou: boolean;
  reviewLocations: ReviewLocation[];
  isLoading: boolean;
  onCloseThankYou: () => void;
  feedbackType: FeedbackType;
}) {
  const { title, subtitle } = getModalTitle(feedbackType);

  return (
    <>
      <Modal open={isModalOpen} onClose={onClose} hideClose>
        <div>
          <div className="flex justify-end">
            <button
              className="rounded-md capitalize disabled:bg-gray-400 transition duration-200 flex items-center gap-2 justify-center cursor-pointer disabled:cursor-not-allowed text-blue-600 px-2 py-1 hover:bg-gray-200"
              onClick={onSkip}
              disabled={isLoading}
            >
              Skip
            </button>
          </div>
          <h2 className="text-center font-medium mt-2">{title}</h2>
          <p className="text-center text-sm ">{subtitle}</p>
          <div className="flex justify-center">
            <div className="grid grid-cols-4 gap-8 mt-4">
              {[0, 1, 2, 3].map((rating) => (
                <label
                  className="flex items-center justify-center"
                  key={rating}
                  onClick={() => onSetFeedback({ rating })}
                >
                  <input
                    id="rating"
                    name="rating"
                    type="radio"
                    value={rating.toString()}
                    className="hidden w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                  <RatingIcon
                    rating={rating}
                    isSelected={rating === feedback?.rating}
                    size="3x"
                  />
                  <span className="hidden ml-4" aria-label={rating.toString()}>
                    {rating}
                  </span>
                </label>
              ))}
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="comment">
              <p>Leave us a comment</p>
              <textarea
                id="comment"
                name="comment"
                className={textAreaClass}
                rows={4}
                value={feedback?.comment}
                onChange={(e) => onSetFeedback({ comment: e.target.value })}
              />
            </label>
          </div>
          <div className="mt-4">
            <Button
              color="secondary"
              className="w-full"
              onClick={onSubmit}
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <ThankYou
        open={showThankYou}
        onClose={onCloseThankYou}
        reviewLocations={reviewLocations}
      />
    </>
  );
}

function getModalTitle(feedbackType: FeedbackType) {
  let title = "";
  let subtitle = "";

  switch (feedbackType) {
    case "MaintenanceAIChat":
      title = "Thank you for using Livly’s Assistant";
      subtitle = "Rate your experience below";
      break;
    default:
      title = `We'd love your feedback!`;
      subtitle = `Tell us what you'd like to improve in your living experience.`;
      break;
  }

  return { title, subtitle };
}

async function postResidentSentiment(
  feedbackId: number,
  feedback: FeedbackRequest
) {
  const result = await axios.post<BaseLivlyApiResponse<Feedback>>(
    `${BASE_API_URL}/resident/feedback/${feedbackId}`,
    feedback
  );

  return result.data.Data;
}

function usePostResidentSentiment(feedbackId: number) {
  const queryClient = useQueryClient();
  const { user } = useLivlyUser();
  const residentSentimentFromLocalStorage = localStorage.getItem(
    RESIDENT_SENTIMENT_TASK_KEY
  );
  const [showThankYou, setShowThankYou] = useState(false);
  const [reviewLocations, setReviewLocations] = useState<ReviewLocation[]>([]);
  const [feedback, setFeedback] = useState<FeedbackRequest>({
    feedbackId: 0,
    rating: null,
    comment: "",
    feedbackItems: [],
  });
  const [isVisible, setIsVisible] = useState(
    (residentSentimentFromLocalStorage === null ||
      residentSentimentFromLocalStorage === "true") ??
      true
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const postSentimentMutation = useMutation({
    mutationFn: (sentiment: FeedbackRequest) =>
      postResidentSentiment(feedbackId, sentiment),
  });

  const onSetFeedback = (newSentiment: Partial<FeedbackRequest>) => {
    setFeedback({ ...feedback, ...newSentiment });
  };

  const onSubmit = async () => {
    postSentimentMutation.mutate(feedback, {
      onSuccess(data) {
        if (
          data.reviewLocations.length > 0 &&
          feedback.rating === 3 &&
          navigator.clipboard
        ) {
          toast.success("Your comment is copied to the clipboard");
          navigator.clipboard.writeText(data.comment);
          setReviewLocations(data.reviewLocations);
        }

        setIsModalOpen(false);
        setShowThankYou(true);
        setFeedback({
          feedbackId,
          rating: null,
          comment: "",
          feedbackItems: [],
        });
      },
      onError: () => {
        toast.error("An error occurred while submitting your feedback");
      },
    });
  };

  const onSkip = async () => {
    postSentimentMutation.mutate(
      { ...feedback, comment: "" },
      {
        onSuccess(data) {
          if (
            data.reviewLocations.length > 0 &&
            feedback.rating === 3 &&
            navigator.clipboard
          ) {
            toast.success("Your comment is copied to the clipboard");
            navigator.clipboard.writeText(data.comment);
            setReviewLocations(data.reviewLocations);
          }

          setIsModalOpen(false);
          setShowThankYou(true);
        },
        onError: () => {
          toast.error("An error occurred while submitting your feedback");
        },
      }
    );
  };

  const onCloseThankYou = () => {
    setShowThankYou(false);
    queryClient.invalidateQueries({ queryKey: ["lease", "tasks"] });
  };

  return {
    user,
    showThankYou,
    reviewLocations,
    onSubmit,
    onSkip,
    isVisible,
    isModalOpen,
    feedback,
    onSetFeedback,
    setIsVisible,
    setIsModalOpen,
    onCloseThankYou,
    isLoading: postSentimentMutation.isLoading,
  };
}
