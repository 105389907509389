export enum TicketState {
  NEW = 1,
  OPEN = 2,
  CLOSED = 3,
  REJECTED = 4,
}
export interface TicketCategory {
  maintenanceCategoryId: string;
  name: string;
  externalId: string;
  externalSubId: string;
  clientId: 0;
  parentId: string;
  hasChildren: true;
  fullCategoryName: string;
}

export interface Ticket {
  ResidentFeedbackId: number | null;
  maintenanceTicketId: number;
  submittedAt: Date;
  ticketStatus: TicketState;
  assignedToUser: TicketUser | null;
  category: TicketCategory;
  propertyUnit: PropertyUnit | null;
  commonSpaceArea: CommonSpaceArea | null;
  submittedByUser: TicketUser;
  leaseId: number;

  isHighPriority?: boolean;
  hasPermissionToEnter?: boolean;
  photos?: TicketPhoto[];
  description: string;
  accessInstructions?: string;
  staffNotes?: string;
}

export interface TicketUser {
  userId: number;
  firstName: string;
  lastName: string;
  avatarUri: string;

  unit?: string;
  timestamp?: Date;
  email?: string;
  phone?: string;
}

export interface PropertyUnit {
  propertyUnitId: number;
  unitNumber: string;
}

export interface CommonSpaceArea {
  commonSpaceAreaId: number;
  name: string;
}

export interface TicketPhoto {
  maintenanceTicketPhotoId: number;
  maintenanceTicketId: number;
  order: number;
  uri: string;
  thumbnailUri: string;
}

export interface PostTicketPhotoRequest {
  maintenanceTicketId?: number;
  base64Image: string;
}

export interface TicketChangeRequest {
  description?: string;
  accessInstructions?: string;
  hasPermissionToEnter?: boolean;
}

export interface TicketCreateRequest {
  maintenanceCategoryId: string;
  hasPermissionToEnter: boolean;
  description: string;
  accessInstructions?: string;
  photos?: TicketPhotoCreateRequest[];
  leaseId?: number;
}

export interface TicketPhotoCreateRequest {
  maintenanceTicketPhotoId: number;
  order?: number;
}

export interface TicketCounter {
  statusId: TicketState;
  count: number;
}

export enum HistoryItemType {
  Submit = 0,
  StatusChanged = 1,
  Assigned = 2,
  CategoryChanged = 3,
  TicketSource = 4,
  PropertyUnitChanged = 5,
  DescriptionChanged = 6,
  AccessInstructionsChanged = 7,
  CommonAreaChanged = 8,
  UnitLocationChanged = 9,
  PriorityChanged = 10,
  HasPermissionToEnterChanged = 11,
  StaffNotesChanged = 12,
  LeaseChanged = 13,
  ErpRequestNumberChanged = 14,
  WatcherAdded = 15,
  WatcherRemoved = 16,
  AttachmentAdded = 17,
}

export interface TicketHistory {
  maintenanceTicketHistoryId: number;
  maintenanceTicketId: number;
  historyItemType: HistoryItemType;
  newValue: string;
  changedAt: Date;
  changedByUserModel: TicketUser;
}

export interface Feedback {
  residentFeedbackId: number;
  leaseLivlyUserId: number;
  feedbackType: FeedbackType | undefined;
  entityId: number;
  metaData: Record<string, string>;
  createdTimestamp: string | Date;
  availableTimestamp: string | Date;
  availableUntilTimestamp: string | Date;
  rating: number;
  comment: string;
  feedbackItems: number[];
  completedTimestamp: string | Date;
  options: {
    positive: FeedbackOption[];
    negative: FeedbackOption[];
  };
  reviewLocations: ReviewLocation[];
}

export type FeedbackType =
  | "MaintenanceTicket"
  | "Property"
  | "AmenityBooking"
  | "Event";

export interface ReviewLocation {
  isDefault: boolean;
  name: string;
  type: number;
  typeImageUrl: string;
  url: string;
}

export interface FeedbackOption {
  residentFeedbackItemOptionTypeId: number;
  description: string;
  isPositive: boolean;
  isNegative: boolean;
  feedbackType: number;
}

export interface FeedbackRequest {
  feedbackId: number;
  rating: number | null;
  comment: string;
  feedbackItems: number[];
}

export interface CreateChannelResponse {
  id: string;
  createdAt?: Date;
  channelId: string;
}
