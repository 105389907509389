import Layout from "../components/Layout";
import desktop from "../assets/download-livly-large.png";
import mobile from "../assets/download-livly-small.png";

export default function CommunityFeedPage() {
  const link = getAppLink();

  return (
    <Layout title="Community Feed">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img
          src={desktop}
          className="hidden w-full md:block"
          alt="download livly"
        />
        <img
          src={mobile}
          className="object-cover w-full md:hidden"
          alt="download livly"
        />
      </a>
    </Layout>
  );
}

export const getAppLink = () => {
  if (import.meta.env.VITE_ENVIRONMENT === "PRODUCTION") {
    return "https://livly.app.link/vQbPtuiQX4";
  } else {
    return "https://livly.test-app.link/28TYfYIQX4";
  }
};
