import { Link, useOutletContext, useParams } from "react-router-dom";
import { Button, ButtonWrapper } from "../../components/Button";
import { LayoutBody, LayoutHeader } from "../../components/Layout";
import { PaymentBlocked, RentDetails } from "../../components/Rent/RentDetails";
import { RentHistoryList } from "../../components/Rent/RentHistoryList";
import { Spinner } from "../../components/Spinner";
import { RentContext } from "../../context/RentProvider";
import useGetRentSettings from "../../hooks/useGetRentSettings";
import { ServiceTypeEnum } from "../../types/Building";
import useLivlyUser from "../../context/UserProvider";
import useGetRentAutoPaySettings from "@/hooks/useGetRentAutoPaySettings";
import useGetRentHistory from "@/hooks/useGetRentHistory";

function LivlyRentPage() {
  const params = useParams<{ leaseId: string; userId: string }>();
  const { user } = useLivlyUser();
  const { data: rentSettings } = useGetRentSettings(
    String(user.propertyUnitLeaseId)
  );
  const rentService = user.building.serviceTypes?.find(
    (serviceType) => serviceType.serviceType === ServiceTypeEnum.Rent
  );
  const { data: rentAutoPay } = useGetRentAutoPaySettings(
    params.leaseId!,
    params.userId!
  );
  const { data: rentHistoryItems = [], isLoading: isRentHistoryLoading } =
    useGetRentHistory(params.leaseId!);
  const {
    selectedPaymentAccount,
    setSelectedPaymentAccountId,
    isBalanceBasedRent,
    paymentAccounts,
  } = useOutletContext() as RentContext;

  const isPayRentButtonVisible =
    ((user.ledgerBalance ?? 0) > 0 &&
      selectedPaymentAccount &&
      rentSettings?.isPaymentAllowed) ||
    (!isBalanceBasedRent &&
      selectedPaymentAccount &&
      rentSettings?.isPaymentAllowed);

  return (
    <div>
      <LayoutHeader title={rentService?.displayName ?? "Rent"} />
      <LayoutBody className="pb-24">
        {rentSettings ? (
          rentSettings?.isPaymentAllowed ? (
            rentAutoPay ? (
              <RentDetails
                isPayRentButtonVisible={isPayRentButtonVisible}
                defaultPaymentAccount={selectedPaymentAccount}
                balance={user.ledgerBalance ?? 0}
                rentAutoPay={rentAutoPay}
                ledgerStatus={user.ledgerStatus}
                paymentAccounts={paymentAccounts}
                setSelectedPaymentAccountId={setSelectedPaymentAccountId}
              />
            ) : null
          ) : (
            <PaymentBlocked balance={user.ledgerBalance ?? 0} />
          )
        ) : null}
        {isRentHistoryLoading ? (
          <div className="flex items-center py-4 gap-4">
            <Spinner color="livly" />
            <span>Loading rent history...</span>
          </div>
        ) : (
          <>
            <RentHistoryList rentHistoryItems={rentHistoryItems} limit={5} />
            {rentHistoryItems.length > 5 && (
              <div className="flex justify-center mt-4">
                <Link
                  to={`../rent/${user.userId}/history`}
                  className="text-red-400 "
                >
                  see payment history
                </Link>
              </div>
            )}
          </>
        )}
      </LayoutBody>
      {isPayRentButtonVisible && (
        <ButtonWrapper>
          <Link to="pay" className="w-full md:w-auto md:hidden">
            <Button
              color="secondary"
              className="w-full md:w-auto"
              type="button"
            >
              Pay Rent
            </Button>
          </Link>
        </ButtonWrapper>
      )}
    </div>
  );
}

export { LivlyRentPage };
