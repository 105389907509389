import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Alert({
  message,
  action,
  title,
  variant = "danger",
}: {
  title?: string;
  message: string;
  action?: { label: string; onClick: () => void };
  variant?: "danger" | "success" | "info" | "default";
}) {
  if (variant === "info") {
    return (
      <div className="p-4 rounded-md bg-blue-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <FontAwesomeIcon
              icon={["far", "circle-exclamation"]}
              className="w-5 h-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            {title ? (
              <h3 className="mb-2 text-sm font-medium text-blue-800">
                {title}
              </h3>
            ) : null}
            <div className="text-sm text-blue-700">
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (variant === "success") {
    return (
      <div className="p-4 rounded-md bg-green-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <FontAwesomeIcon
              icon={["far", "check-circle"]}
              className="w-5 h-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <div className="text-sm text-green-700">
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (variant === "default") {
    return (
      <div className="p-4 bg-gray-100 rounded-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <FontAwesomeIcon
              icon={["far", "check-circle"]}
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <div className="text-sm text-gray-700">
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 rounded-md bg-red-50">
      <div className="flex">
        <div className="flex-shrink-0">
          <FontAwesomeIcon
            icon="exclamation-triangle"
            className="w-5 h-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">We are sorry</h3>
          <div className="mt-2 text-sm text-red-700">
            <p>{message}</p>
          </div>
          {action && (
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <button
                  onClick={action.onClick}
                  type="button"
                  className="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                >
                  {action.label}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
