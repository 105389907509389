import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import RequireAuth from "./components/RequireAuth";
import AmenitiesPage from "./routes/amenities";
import AmenitySpacePage from "./routes/amenity-space";
import CallbackPage from "./routes/login/callback";
import AddGuestPage from "./routes/guests/add-guest";
import EditGuestPage from "./routes/guests/edit-guest";
import GuestsPage from "./routes/guests/my-guests";
import Home from "./routes/home";
import LeasePage from "./routes/lease";
import LoginPage from "./routes/login/login-email";
import LogoutPage from "./routes/login/logout";
import MyLeasePage from "./routes/my-lease";
import AddPetPage from "./routes/pets/add-pet";
import EditPetPage from "./routes/pets/edit-pet";
import PetsPage from "./routes/pets/pets";
import AddVehiclePage from "./routes/vehicles/add-vehicle";
import EditVehiclePage from "./routes/vehicles/edit-vehicle";
import VehiclesPage from "./routes/vehicles/vehicles";
import BookingsPage from "./routes/bookings";
import NewBookingPage from "./routes/booking/add-booking";
import EditBookingPage from "./routes/booking/edit-booking";
import ConfirmBookingPage from "./routes/booking/confirm-booking";
import SelectDateTimePage from "./routes/booking/select-date-time";
import { FitnessPage } from "./routes/fitness";
import EventsPage from "./routes/events";
import EventPage from "./routes/event";
import MarketplacePage from "./routes/marketplace";
import MarketplaceOfferPage from "./routes/marketplace-offer";
import ForgotPasswordPage from "./routes/login/forgot-password";
import SignUpPage from "./routes/login/sign-up";
import PasswordlessLoginPage from "./routes/login/login";
import CommunityFeedPage from "./routes/community-feed";
import MaintenancePage from "./routes/maintenance/maintenance";
import auth from "./utils/auth";
import AddMaintenanceTicketPage from "./routes/maintenance/add-ticket";
import AddTicketMobile from "./routes/maintenance/m.add-ticket";
import ViewTicketPage from "./routes/maintenance/view-ticket";
import ViewTicketMobile from "./routes/maintenance/m.view-ticket";
import MaintenanceTicketRateExperiencePage from "./routes/maintenance/rate-experience";
import TicketChatPage from "./routes/maintenance/ticket-chat";
import WalletPage from "./routes/wallet/wallet";
import DeletePaymentAccountPage from "./routes/wallet/delete-account";
import VerifyAccountPage from "./routes/wallet/verify-account";
import AddPaymentMethodPage from "./routes/wallet/add";
import AddCardPage from "./routes/wallet/add-card";
import AddACHPage from "./routes/wallet/add-ach";
import { LinkProvider } from "./context/LinkToken";
import InitiateAccountPage from "./routes/wallet/initiate-account";
import ChecklistLandingPage from "./routes/checklist/landing";
import ChecklistProfilePage from "./routes/checklist/profile";
import ChecklistRoommatesPage from "./routes/checklist/roommates";
import ChecklistAvatarPage from "./routes/checklist/avatar";
import ChecklistScheduleMoveInPage from "./routes/checklist/schedule-move-in";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ChecklistUtilitiesPage from "./routes/checklist/utilities";
import UtilityPage from "./routes/checklist/utility/utility";
import ChecklistWalletPage from "./routes/checklist/wallet";
import ChecklistInsurancePage from "./routes/checklist/insurance";
import ChecklistCartPage from "./routes/checklist/cart";
import ChecklistCheckoutPage from "./routes/checklist/checkout";
import "./utils/axios";
import CartProvider from "./context/CartProvider";
import ChecklistCheckoutConfirmationPage from "./routes/checklist/checkout-confirmation";
import ChecklistCommunitySurveyPage from "./routes/checklist/community-survey";
import RentPage from "./routes/rent/rent";
import ChecklistCheckoutInsurancePage from "./routes/checklist/checkout-insurance";
import FriendsAndFamilyLoginsPage from "./routes/family-logins/family-logins";
import AddFamilyLoginPage from "./routes/family-logins/add-login";
import AddFamilyLoginDetailsPage from "./routes/family-logins/details";
import AddFamilyLoginPermissionsPage from "./routes/family-logins/permissions";
import EditFamilyLoginPage from "./routes/family-logins/edit";
import CommunitySurveyPage from "./routes/community-survey";
import { LivlyRentPage } from "./routes/rent/rent-livly";
import { RentAutoPayPage } from "./routes/rent/autopay";
import { RentHistoryPage } from "./routes/rent/history";
import RentProvider from "./context/RentProvider";
import { LivlyRentPayPage } from "./routes/rent/pay";
import { LivlyRentConfirmationPage } from "./routes/rent/confirmation";
import { InsurancePage } from "./routes/insurance";
import { InsuranceProofPage } from "./routes/insurance-proof";
import {
  InspectionChecklistContainer,
  InspectionChecklistPage,
} from "./routes/inspection-checklist";
import { InspectionChecklistSectionPage } from "./routes/inspection-checklist-section";
import { LeaseRenewalPage } from "./routes/renewal";
import { InsuranceMarketplacePage } from "./routes/checklist/insurance-marketplace";
import { ChecklistInsuranceProofPage } from "./routes/checklist/insurance-proof";
import { FeedbackPage } from "./routes/feedback";
import { TestWebViewPage } from "./routes/test-webview";
import { Toaster } from "react-hot-toast";
import { NotificationsPage } from "./routes/notifications";
import {
  SelectLeasePage,
  loader as selectLeaseLoader,
} from "./routes/select-lease";
import { SentimentPage } from "./routes/sentiment";
import {
  FitnessPaymentMethodsPage,
  loader as fitnessPaymentAccountsLoader,
} from "./routes/fitness-payment-methods";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FitnessActivityDetailsPage } from "./routes/fitness-details";
import { InsuranceQuotePage } from "./routes/checklist/insurance-quote";
import { InsurancePaymentPlanPage } from "./routes/checklist/insurance-payment-plan";
import { InsuranceCoveragesPage } from "./routes/checklist/insurance-coverages";
import { InsuranceAddressPage } from "./routes/checklist/insurance-address";
import {
  InsuranceAddressNewPage,
  action as addAddressAction,
} from "./routes/checklist/insurance-address-add";
import {
  InsuranceAddressEditPage,
  action as updateAddressAction,
} from "./routes/checklist/insurance-address-edit";
import { UserProvider } from "./context/UserProvider";
import { ChatProvider } from "./context/ChatProvider";
import { TicketChannelProvider } from "./context/TicketChannelsProvider";
import PetsAndVehiclesPage from "./routes/checklist/pets-vehicles";
import ChecklistPetsPage from "./routes/checklist/pets";
import ChecklistPetsAddPage from "./routes/checklist/pets-add";
import ChecklistEditPetPage from "./routes/checklist/pets-edit";
import ChecklistVehiclesPage from "./routes/checklist/vehicles";
import ChecklistVehiclesAddPage from "./routes/checklist/vehicles-add";
import ChecklistVehicleEditPage from "./routes/checklist/vehicles-edit";
import TicketAIChatPage from "./routes/maintenance/ai-chat";
import { GuestsIndexPage } from "./routes/guests/guests";
import SettingsPage from "./routes/user-settings";
import PreferredNamePage from "./routes/preferred-name";
import FeedbackAndReviewsPage from "./routes/feedback.reviews";
import ResidentPollPage from "./routes/resident.polls.$id";
import InsuranceQuoteLayout from "./components/InsuranceQuoteLayout";
import CurrentResidentUtilitiesPage from "./routes/utilities";
import { Poll } from "./routes/polls/Poll";
import PollThankyou from "./routes/polls/PollThankYou";
import { PollsContainer } from "./routes/polls/PollsContainer";
import Permission from "./routes/guests/permission";
import MarketplaceLayout from "./context/MarketplaceLayout";
import FeatureTogglesPage from "./routes/features";
import ChecklistProvider from "./context/ChecklistProvider";
import RentRealPage from "./routes/rent/real-page";
import { GuestRequestProvider } from "./context/GuestProvider";
import { LivlyFeaturePermission } from "./routes/guests/livly-feature-permission";
import { GuestReview } from "./routes/guests/review";
import ChecklistBuildingOffersPage from "./routes/checklist/building-offers";
import { UIProvider } from "./context/UIProvider";
import ChecklistBuildingOfferPage from "./routes/checklist/building-offer";
import ChecklistCheckoutAmount from "./routes/checklist/checkout-amount";
import LivlyKeyPermission from "./routes/guests/livly-key";
import NoAccessPage from "./routes/no-access";
import TermsOfServicePage from "./routes/terms-of-service";
import EditFeedbackAndReviewsPage from "./routes/feedback.reviews.edit";
import ResourcePage from "./routes/resource-page";
import NotFoundPage from "./routes/not-found";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/lease"
        index
        loader={selectLeaseLoader}
        element={
          <RequireAuth>
            <SelectLeasePage />
          </RequireAuth>
        }
      />
      <Route path="features" element={<FeatureTogglesPage />} />
      <Route path="/lease/:leaseId">
        <Route
          element={
            <RequireAuth>
              <UserProvider />
            </RequireAuth>
          }
        >
          <Route
            element={
              <RequireAuth>
                <LeasePage />
              </RequireAuth>
            }
          >
            <Route path=":buildingId/page/:pageId" element={<ResourcePage />} />
            <Route
              index
              element={
                <RequireAuth>
                  <Navigate to="home" />
                </RequireAuth>
              }
            />
            <Route
              path="settings"
              element={
                <RequireAuth>
                  <SettingsPage />
                </RequireAuth>
              }
            />
            <Route
              path="terms-of-service"
              element={
                <RequireAuth>
                  <TermsOfServicePage />
                </RequireAuth>
              }
            />
            <Route
              path="preferred-name"
              element={
                <RequireAuth>
                  <PreferredNamePage />
                </RequireAuth>
              }
            />
            <Route
              path="test-webview"
              element={
                <RequireAuth>
                  <TestWebViewPage />
                </RequireAuth>
              }
            />

            <Route
              path="my-feedback/polls/:pollId"
              element={<ResidentPollPage />}
            />
            <Route
              path="my-feedback"
              element={
                <RequireAuth>
                  <FeedbackPage />
                </RequireAuth>
              }
            >
              <Route
                path="reviews"
                element={
                  <RequireAuth>
                    <FeedbackAndReviewsPage />
                  </RequireAuth>
                }
              >
                <Route
                  path="edit/:reviewId"
                  element={<EditFeedbackAndReviewsPage />}
                />
              </Route>
              <Route path="polls">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <PollsContainer />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route index element={<Navigate to="reviews" />} />
            </Route>
            <Route path="polls">
              <Route
                index
                path=":pollId"
                element={
                  <RequireAuth>
                    <Poll />
                  </RequireAuth>
                }
              />
              <Route path="thank-you" element={<PollThankyou />} />
            </Route>

            <Route path="checklist/:userId" element={<ChecklistProvider />}>
              <Route
                index
                element={
                  <RequireAuth>
                    <ChecklistLandingPage />
                  </RequireAuth>
                }
              />
              <Route element={<MarketplaceLayout />}>
                <Route
                  path="building-offers"
                  element={
                    <RequireAuth>
                      <ChecklistBuildingOffersPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="building-offers/:vendorId/:offerId"
                  element={
                    <RequireAuth>
                      <ChecklistBuildingOfferPage />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route
                path="profile"
                element={
                  <RequireAuth>
                    <ChecklistProfilePage />
                  </RequireAuth>
                }
              />
              <Route
                path="roommates"
                element={
                  <RequireAuth>
                    <ChecklistRoommatesPage />
                  </RequireAuth>
                }
              />
              <Route
                path="avatar"
                element={
                  <RequireAuth>
                    <ChecklistAvatarPage />
                  </RequireAuth>
                }
              />
              <Route
                path="insurance"
                element={
                  <RequireAuth>
                    <ChecklistInsurancePage />
                  </RequireAuth>
                }
              />
              <Route
                path="insurance-marketplace/:buildingId"
                element={
                  <RequireAuth>
                    <InsuranceMarketplacePage />
                  </RequireAuth>
                }
              />
              <Route element={<InsuranceQuoteLayout />}>
                <Route
                  path="insurance-quote"
                  element={
                    <RequireAuth>
                      <InsuranceQuotePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="insurance-quote/payment-plan"
                  element={
                    <RequireAuth>
                      <InsurancePaymentPlanPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="insurance-quote/coverages"
                  element={
                    <RequireAuth>
                      <InsuranceCoveragesPage />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route
                path="insurance-quote/address"
                element={
                  <RequireAuth>
                    <InsuranceAddressPage />
                  </RequireAuth>
                }
              />
              <Route
                path="insurance-quote/address/new"
                action={addAddressAction}
                element={
                  <RequireAuth>
                    <InsuranceAddressNewPage />
                  </RequireAuth>
                }
              />
              <Route
                path="insurance-quote/address/:id"
                action={updateAddressAction}
                element={
                  <RequireAuth>
                    <InsuranceAddressEditPage />
                  </RequireAuth>
                }
              />
              <Route
                path="insurance/upload-proof"
                element={
                  <RequireAuth>
                    <ChecklistInsuranceProofPage />
                  </RequireAuth>
                }
              />
              <Route
                path="community-survey"
                element={
                  <RequireAuth>
                    <ChecklistCommunitySurveyPage />
                  </RequireAuth>
                }
              />
              <Route
                path="rent"
                element={
                  <RequireAuth>
                    <RentRealPage />
                  </RequireAuth>
                }
              />
              <Route element={<CartProvider />}>
                <Route
                  path="cart"
                  element={
                    <RequireAuth>
                      <ChecklistCartPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="cart/checkout"
                  element={
                    <RequireAuth>
                      <ChecklistCheckoutPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="cart/checkout-amount"
                  element={
                    <RequireAuth>
                      <ChecklistCheckoutAmount />
                    </RequireAuth>
                  }
                />
                <Route
                  path="cart/checkout-insurance"
                  element={
                    <RequireAuth>
                      <ChecklistCheckoutInsurancePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="cart/confirmation"
                  element={
                    <RequireAuth>
                      <ChecklistCheckoutConfirmationPage />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route
                path="schedule-move-in"
                element={
                  <RequireAuth>
                    <ChecklistScheduleMoveInPage />
                  </RequireAuth>
                }
              />
              <Route id="checklistUtilities">
                <Route
                  path="utilities/:userId"
                  element={
                    <RequireAuth>
                      <ChecklistUtilitiesPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="utilities/:userId/:utilityId"
                  element={
                    <RequireAuth>
                      <UtilityPage />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="pets-vehicles/:buildingId">
                <Route
                  index
                  element={
                    <RequireAuth redirectTo="/login">
                      <PetsAndVehiclesPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="pets"
                  element={
                    <RequireAuth redirectTo="/login">
                      <ChecklistPetsPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="pets/add"
                  element={
                    <RequireAuth redirectTo="/login">
                      <ChecklistPetsAddPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="pets/:id"
                  element={
                    <RequireAuth redirectTo="/login">
                      <ChecklistEditPetPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="vehicles"
                  element={
                    <RequireAuth redirectTo="/login">
                      <ChecklistVehiclesPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="vehicles/add"
                  element={
                    <RequireAuth redirectTo="/login">
                      <ChecklistVehiclesAddPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="vehicles/:id"
                  element={
                    <RequireAuth redirectTo="/login">
                      <ChecklistVehicleEditPage />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route
                path="wallet"
                element={
                  <RequireAuth>
                    <ChecklistWalletPage />
                  </RequireAuth>
                }
              >
                <Route
                  path=":id/delete"
                  element={
                    <RequireAuth>
                      <DeletePaymentAccountPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path=":id/verify"
                  element={
                    <RequireAuth>
                      <VerifyAccountPage />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route
                path="wallet/add"
                element={
                  <RequireAuth>
                    <AddPaymentMethodPage />
                  </RequireAuth>
                }
              />
              <Route
                path="wallet/add/card"
                element={
                  <RequireAuth>
                    <AddCardPage />
                  </RequireAuth>
                }
              />
              <Route
                path="wallet/add/ach"
                element={
                  <RequireAuth>
                    <AddACHPage />
                  </RequireAuth>
                }
              />
              <Route
                path="wallet/initiate-account"
                element={
                  <RequireAuth>
                    <InitiateAccountPage />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="amenities/:propertyId"
              element={
                <RequireAuth>
                  <AmenitiesPage />
                </RequireAuth>
              }
            />
            <Route path="amenities/:propertyId/:id" id="amenityDetails">
              <Route
                index
                element={
                  <RequireAuth>
                    <AmenitySpacePage />
                  </RequireAuth>
                }
              />
              <Route
                path="booking/new"
                element={
                  <RequireAuth>
                    <NewBookingPage />
                  </RequireAuth>
                }
              >
                <Route
                  index
                  element={
                    <RequireAuth>
                      <SelectDateTimePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="confirm"
                  element={
                    <RequireAuth>
                      <ConfirmBookingPage />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route
                path="booking/:bookingId"
                element={
                  <RequireAuth>
                    <EditBookingPage />
                  </RequireAuth>
                }
              >
                <Route
                  index
                  element={
                    <RequireAuth>
                      <SelectDateTimePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="confirm"
                  element={
                    <RequireAuth>
                      <ConfirmBookingPage />
                    </RequireAuth>
                  }
                />
              </Route>
            </Route>
            <Route
              path="bookings"
              element={
                <RequireAuth>
                  <BookingsPage />
                </RequireAuth>
              }
            />
            <Route
              path="home"
              element={
                <RequireAuth redirectTo="/login">
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="my-lease"
              element={
                <RequireAuth redirectTo="/login">
                  <MyLeasePage />
                </RequireAuth>
              }
            />
            <Route
              path="utilities/:userId"
              element={<CurrentResidentUtilitiesPage />}
            />
            <Route
              path="utilities/:userId/:utilityId"
              element={<UtilityPage />}
            />

            <Route
              path="pets"
              element={
                <RequireAuth redirectTo="/login">
                  <PetsPage />
                </RequireAuth>
              }
            />
            <Route
              path="pets/add"
              element={
                <RequireAuth redirectTo="/login">
                  <AddPetPage />
                </RequireAuth>
              }
            />
            <Route
              path="pets/:id"
              element={
                <RequireAuth redirectTo="/login">
                  <EditPetPage />
                </RequireAuth>
              }
            />
            <Route
              path="vehicles"
              element={
                <RequireAuth redirectTo="/login">
                  <VehiclesPage />
                </RequireAuth>
              }
            />
            <Route
              path="vehicles/add"
              element={
                <RequireAuth redirectTo="/login">
                  <AddVehiclePage />
                </RequireAuth>
              }
            />
            <Route
              path="vehicles/:id"
              element={
                <RequireAuth redirectTo="/login">
                  <EditVehiclePage />
                </RequireAuth>
              }
            />
            <Route element={<GuestRequestProvider />}>
              <Route path="guests" element={<GuestsIndexPage />}>
                <Route path="add">
                  <Route
                    index
                    element={
                      <RequireAuth redirectTo="/login">
                        <AddGuestPage />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="permission"
                    element={
                      <RequireAuth redirectTo="/login">
                        <Permission />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="livly-key-permission"
                    element={
                      <RequireAuth redirectTo="/login">
                        <LivlyKeyPermission />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="livly-feature-permission"
                    element={
                      <RequireAuth redirectTo="/login">
                        <LivlyFeaturePermission />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="review"
                    element={
                      <RequireAuth redirectTo="/login">
                        <GuestReview />
                      </RequireAuth>
                    }
                  />
                </Route>

                <Route path=":id">
                  <Route
                    index
                    element={
                      <RequireAuth redirectTo="/login">
                        <EditGuestPage />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="permission"
                    element={
                      <RequireAuth redirectTo="/login">
                        <Permission />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="livly-key-permission"
                    element={
                      <RequireAuth redirectTo="/login">
                        <LivlyKeyPermission />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="livly-feature-permission"
                    element={
                      <RequireAuth redirectTo="/login">
                        <LivlyFeaturePermission />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="review"
                    element={
                      <RequireAuth redirectTo="/login">
                        <GuestReview />
                      </RequireAuth>
                    }
                  />
                </Route>
                <Route
                  index
                  element={
                    <RequireAuth redirectTo="/login">
                      <GuestsPage />
                    </RequireAuth>
                  }
                />
              </Route>
            </Route>
            <Route path="fitness/:propertyId">
              <Route
                path=":id"
                element={
                  <RequireAuth>
                    <FitnessActivityDetailsPage />
                  </RequireAuth>
                }
              />
              <Route
                index
                element={
                  <RequireAuth>
                    <FitnessPage />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="events/property/:propertyId"
              element={
                <RequireAuth>
                  <EventsPage />
                </RequireAuth>
              }
            />
            <Route
              path="events/property/:propertyId/:eventId"
              element={
                <RequireAuth>
                  <EventPage />
                </RequireAuth>
              }
            />
            <Route element={<MarketplaceLayout />}>
              <Route
                path="marketplace"
                element={
                  <RequireAuth>
                    <MarketplacePage />
                  </RequireAuth>
                }
              />
              <Route
                path="marketplace/:vendorId/:offerId"
                element={
                  <RequireAuth>
                    <MarketplaceOfferPage />
                  </RequireAuth>
                }
              />
            </Route>
            <Route element={<ChecklistProvider />}>
              <Route element={<InsuranceQuoteLayout />}>
                <Route
                  path="insurance"
                  element={
                    <RequireAuth redirectTo="/login">
                      <InsurancePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="insurance-quote"
                  element={
                    <RequireAuth>
                      <InsuranceQuotePage isInsuranceOnly />
                    </RequireAuth>
                  }
                />
                <Route
                  path="insurance-quote/payment-plan"
                  element={
                    <RequireAuth>
                      <InsurancePaymentPlanPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="insurance-quote/coverages"
                  element={
                    <RequireAuth>
                      <InsuranceCoveragesPage />
                    </RequireAuth>
                  }
                />
                <Route element={<CartProvider />}>
                  <Route
                    path="insurance-quote/checkout"
                    element={
                      <RequireAuth>
                        <ChecklistCheckoutInsurancePage isInsuranceOnly />
                      </RequireAuth>
                    }
                  />
                </Route>
              </Route>
            </Route>
            <Route
              path="insurance-quote/address"
              element={
                <RequireAuth>
                  <InsuranceAddressPage />
                </RequireAuth>
              }
            />
            <Route
              path="insurance-quote/address/new"
              action={addAddressAction}
              element={
                <RequireAuth>
                  <InsuranceAddressNewPage />
                </RequireAuth>
              }
            />
            <Route
              path="insurance-quote/address/:id"
              action={updateAddressAction}
              element={
                <RequireAuth>
                  <InsuranceAddressEditPage />
                </RequireAuth>
              }
            />
            <Route
              path="insurance/upload-proof"
              element={
                <RequireAuth redirectTo="/login">
                  <InsuranceProofPage />
                </RequireAuth>
              }
            />
            <Route element={<ChatProvider />}>
              <Route>
                <Route
                  path="maintenance/:propertyId/m"
                  element={<TicketChannelProvider />}
                >
                  <Route
                    path=":ticketId"
                    element={
                      <RequireAuth>
                        <ViewTicketMobile />
                      </RequireAuth>
                    }
                  >
                    <Route
                      path="chat"
                      element={
                        <RequireAuth>
                          <TicketChatPage />
                        </RequireAuth>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="maintenance/:propertyId"
                  element={
                    <RequireAuth>
                      <MaintenancePage />
                    </RequireAuth>
                  }
                >
                  <Route path="ai" element={<TicketAIChatPage />} />
                  <Route
                    path="m/add-ticket"
                    element={
                      <RequireAuth>
                        <AddTicketMobile />
                      </RequireAuth>
                    }
                  />
                </Route>

                <Route element={<TicketChannelProvider />}>
                  <Route
                    path="maintenance/:propertyId/:ticketId"
                    element={
                      <RequireAuth>
                        <ViewTicketPage />
                      </RequireAuth>
                    }
                  >
                    <Route
                      path="chat"
                      element={
                        <RequireAuth>
                          <TicketChatPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="rate-experience"
                      element={
                        <RequireAuth>
                          <MaintenanceTicketRateExperiencePage />
                        </RequireAuth>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="maintenance/:propertyId/add-ticket"
                  element={
                    <RequireAuth>
                      <AddMaintenanceTicketPage />
                    </RequireAuth>
                  }
                />

                <Route
                  path="maintenance/:propertyId"
                  element={<MaintenanceIndexPage />}
                />
              </Route>
            </Route>
            <Route
              path="community-feed"
              element={
                <RequireAuth>
                  <CommunityFeedPage />
                </RequireAuth>
              }
            />
            <Route
              path="wallet"
              element={
                <RequireAuth>
                  <WalletPage />
                </RequireAuth>
              }
            >
              <Route
                path=":id/delete"
                element={
                  <RequireAuth>
                    <DeletePaymentAccountPage />
                  </RequireAuth>
                }
              />
              <Route
                path=":id/verify"
                element={
                  <RequireAuth>
                    <VerifyAccountPage />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="wallet/add"
              element={
                <RequireAuth>
                  <AddPaymentMethodPage />
                </RequireAuth>
              }
            />
            <Route
              path="wallet/add/card"
              element={
                <RequireAuth>
                  <AddCardPage />
                </RequireAuth>
              }
            />
            <Route
              path="wallet/add/ach"
              element={
                <RequireAuth>
                  <AddACHPage />
                </RequireAuth>
              }
            />
            <Route
              path="wallet/initiate-account"
              element={
                <RequireAuth>
                  <InitiateAccountPage />
                </RequireAuth>
              }
            />
            <Route
              path="rent/:userId"
              element={
                <RequireAuth>
                  <RentPage />
                </RequireAuth>
              }
            />
            <Route
              id="rent"
              element={
                <RequireAuth>
                  <RentProvider />
                </RequireAuth>
              }
            >
              <Route
                path="rent/:userId/livly"
                element={
                  <RequireAuth>
                    <LivlyRentPage />
                  </RequireAuth>
                }
              />
              <Route
                path="rent/:userId/livly/pay"
                element={
                  <RequireAuth>
                    <LivlyRentPayPage />
                  </RequireAuth>
                }
              />
              <Route
                path="rent/:userId/livly/confirmation"
                element={
                  <RequireAuth>
                    <LivlyRentConfirmationPage />
                  </RequireAuth>
                }
              />
              <Route
                path="rent/:userId/history"
                element={
                  <RequireAuth>
                    <RentHistoryPage />
                  </RequireAuth>
                }
              />
              <Route
                path="rent/:userId/autopay"
                element={
                  <RequireAuth>
                    <RentAutoPayPage />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="family-logins"
              element={
                <RequireAuth>
                  <FriendsAndFamilyLoginsPage />
                </RequireAuth>
              }
            />
            <Route
              path="family-logins/:id"
              element={
                <RequireAuth>
                  <EditFamilyLoginPage />
                </RequireAuth>
              }
            />
            <Route
              path="family-logins/add-login"
              element={
                <RequireAuth>
                  <AddFamilyLoginPage />
                </RequireAuth>
              }
            >
              <Route
                path="details"
                element={
                  <RequireAuth>
                    <AddFamilyLoginDetailsPage />
                  </RequireAuth>
                }
              />
              <Route
                path="permissions"
                element={
                  <RequireAuth>
                    <AddFamilyLoginPermissionsPage />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="community-survey"
              element={
                <RequireAuth>
                  <CommunitySurveyPage />
                </RequireAuth>
              }
            />
            <Route
              path="renewer"
              element={
                <RequireAuth>
                  <LeaseRenewalPage />
                </RequireAuth>
              }
            />
            <Route
              id="inspection-checklist"
              element={<InspectionChecklistContainer />}
            >
              <Route
                path="inspection-checklist"
                element={
                  <RequireAuth>
                    <InspectionChecklistPage />
                  </RequireAuth>
                }
              >
                <Route
                  path=":sectionId"
                  element={
                    <RequireAuth>
                      <InspectionChecklistSectionPage />
                    </RequireAuth>
                  }
                />
              </Route>
            </Route>
            <Route
              path="notifications"
              element={
                <RequireAuth>
                  <NotificationsPage />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route index element={<div>lease home page</div>} />
        </Route>
      </Route>
      <Route
        path="/fitness/payment-methods"
        loader={fitnessPaymentAccountsLoader}
        element={<FitnessPaymentMethodsPage />}
      />
      <Route path="/sentiment" element={<SentimentPage />} />
      <Route
        path="/loginflats"
        element={<PasswordlessLoginPage brand="Flats" />}
      />
      <Route
        path="/logincommon"
        element={<PasswordlessLoginPage brand="Common" />}
      />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/login-email" element={<LoginPage />} />
      <Route path="/login" element={<PasswordlessLoginPage />} />
      <Route path="/no-access" element={<NoAccessPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<LogoutPage />} />
    </>
  )
);

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UIProvider>
        <LinkProvider>
          <RouterProvider router={router} />
          <Toaster />
        </LinkProvider>
      </UIProvider>
      {import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

function MaintenanceIndexPage() {
  return <Navigate to="open" />;
}

function LandingPage() {
  if (auth.isAuthenticated()) {
    return <Navigate to="/lease" />;
  } else {
    return <Navigate to="/login" />;
  }
}
