import { Building } from "./Building";
import { LedgerStatus } from "./Rent";

export interface User {
  isLivlyKeyEnabled: boolean;
  address: string;
  addresses: Address[];
  auth0UserID: string;
  avatarURI: string;
  building: Building;
  buildingAddressId: number;
  buildingName: string;
  city: string;
  clientId: number;
  chatAccessToken: string | null;
  electricCompany?: string;
  email: string;
  emergencyContactName?: string;
  emergencyContactPhone?: string;
  emergencyContactRelationship?: string;
  firstMonthPayment: 0;
  firstName: string;
  fullName: string;
  gasCompany?: string;
  guarantorId?: number;
  id: number;
  insuranceCompany?: string;
  isCustomerAdmin?: boolean;
  isInAuth0: boolean;
  isInBinway: boolean;
  isMaintenanceRole?: boolean;
  isRentersInsuranceCompleted: boolean;
  isRentersInsurancePurchased: boolean;
  isTosAccepted: boolean;
  lastName: string;
  leaseEndDate: string;
  leaseId?: number;
  leaseUserType: LeaseUserTypeEnum;
  leaseMoveInDate: string;
  leaseStartDate: string;
  leaseStatusId: number;
  ledger?: LedgerItem[];
  licensePlate?: string;
  onboardingStatuses: OnboardingStatus[];
  password?: string;
  paymentAccounts?: PaymentAccount[];
  pets?: Pet[];
  phone?: string;
  presentation: Presentation;
  profile?: null;
  proofOfInsurance?: null;
  propertyCode: string;
  propertyId: number;
  propertyUnitLeaseId: number;
  rentAmount: number;
  roommates?: User[];
  state: string;
  status: UserStatus;
  streamUserId: string | null;
  stripeId?: null;
  unformattedPhone?: string;
  unit: string;
  userId: number;
  userType: UserType;
  vehicleMake?: string;
  vehicleModel?: string;
  vybiconId?: string | null;
  yardiId: string;
  zipCode: string;
  ledgerBalance?: number;
  ledgerStatus?: LedgerStatus;
  leases: Lease[];
}

export enum LeaseUserTypeEnum {
  Primary = 1,
  Roommate,
  Guarantor,
  Spouse,
  Other,
  Delegated,
  NonResident,
}

export interface Lease {
  propertyUnitLeaseId: number;
  leaseEndDate: string;
  leaseStartDate: string;
  leaseMoveInDate: string;
  status: string;
  propertyCode: string;
  leaseStatusId: LeaseStatusEnum;
  userType: string;
  address: string;
  buildingAddressId: number;
  city: string;
  state: string;
  zipCode: string;
  building: string;
  unit: string;
  logoUrl: string;
}

export interface PaymentAccount {
  brand: string;
  brandImageUrl: string;
  description: string;
  dwollaFundingSource: string | null;
  id: number;
  isActive: boolean;
  isDefault: boolean;
  isVerified: boolean | null;
  paymentType: "Credit Card" | "ACH";
  paymentTypeId: number;
  stripeSourceId: string;
}

export interface OnboardingStatus {
  enabled: boolean;
  id: number;
  isCompleted: boolean;
  isSkipped: boolean;
  metaData?: string;
  name: string;
  notes: string;
  onBoardingStep: number;
  secondsSpent: number;
  statusTypeId: OnboardingStatusEnum;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  id: number;
  isLeaseAddress: boolean;
  latitude: number | null;
  longitude: number | null;
  state: string;
  zipCode: string;
}

export interface Pet {
  petType: string;
  petName: string;
  petBreed: string;
  petWeight: string;
}

export const USER_STATUS = {
  CURRENT: "Current",
  APPLICANT: "Applicant",
  PAST: "Past",
};

export enum UserType {
  Primary = "Primary",
  Roommate = "Roommate",
  Guarantor = "Guarantor",
  Spouse = "Spouse",
  Other = "Other",
  Delegated = "Delegated",
  NonResident = "Non-Resident",
}

export type UserStatus =
  | typeof USER_STATUS.APPLICANT
  | typeof USER_STATUS.CURRENT;

export enum LeaseStatusEnum {
  Past = 1,
  Current,
  Notice,
  Evict,
  Future,
  Applicant,
  Denied,
  Canceled,
}

export interface LedgerItem {
  amount: number;
  balance: number;
  chargeCode: string | number | null;
  date: string | Date;
  description: string;
  id: number;
  leaseId: number;
  notes: string;
  type: string;
  userId: number | null;
  yardiId: string;
}

export type Presentation = {
  presentationType: BuildingExperienceEnum;
  primaryServiceTypeId: BuildingExperiencePrimaryServiceType | null;
  buildingType: BuildingTypeEnum;
};

export enum BuildingTypeEnum {
  Apartments = "Apartments",
  Condos = "Condos",
}

export enum BuildingExperiencePrimaryServiceType {
  Amenities,
  Packages,
  CommunityFeed,
  LivlyEvents,
  BuildingOffers,
}

export enum BuildingExperienceEnum {
  Default = "Default",
  Standalone = "Standalone",
}

export enum OnboardingStatusEnum {
  profile = 1,
  insurance = 2,
  payment = 3,
  checkout = 4,
  schduleMoveIn = 5,
  utilities = 6,

  communitySurvey = 7,
  petAndVehicles = 8,
  marketplace = 11,
}

export type Provider = {
  logoUrl: string;
  name: string;
  utilityProviderId: number;
  utilityProviderTypeId: number;
};

export type UtilityProvider = {
  accountNumberRequired: boolean | null;
  instructions: string;
  isDocumentRequired: boolean | null;
  isResidentDecision: boolean | null;
  logoUrl: string;
  name: string;
  phoneNumber: string;
  propertyId: number;
  propertyUtilityProviderId: number;
  setupRequired: boolean;
  utilityProviderTypeId: number;
  websiteUrl: string;
};

export type LeaseUtilityProvider = {
  accountNumber: string | null;
  accountNumberRequired: boolean;
  documentRequired: boolean;
  documentUri: string | null;
  hasDocument: boolean;
  isComplete: boolean;
  isResidentDecision: boolean | null;
  leaseUtilityProviderId: number;
  logoUrl: string | null;
  name: string | null;
  setupRequired: boolean;
  utilityProviderTypeId: number | null;
  utilityProviders: UtilityProvider[];
  utilityType: UtilityType;
  utilityTypeId: UtilityTypeEnum;
};

export type UtilityType = "Electric" | "Gas" | "Cable" | "Internet" | "Water";

export enum UtilityTypeEnum {
  Electric = 1,
  Gas,
  Cable,
  Internet,
  Water,
}

export type PaymentHistoryItem = {
  amount: number;
  chargeCode: string | null;
  description: string;
  fee: number;
  formattedAmount: string;
  formattedFee: string;
  isAutoPay: false;
  notes: string;
  payerUserId: number;
  paymentMethodId: number;
  postedDate: string;
  status: "failed" | "pending" | "succeeded";
  transactionDate: string;
  transactionId: number;
  transactionTypeId: TransactionType;
};

export enum TransactionType {
  CHARGE = 1,
  PAYMENT = 2,
}

export type Task = {
  alertType: string;
  priority: number;
  metaData?: Record<string, any>;
};

export enum TaskAlertType {
  Renewal = "Renewal",
  ResidentPoll = "ResidentPoll",
  ResidentSentiment = "ResidentSentiment",
  Feedback = "Feedback",
}

export type RenewalSectionType = "Yes" | "No" | "Deciding" | "Transfer";

export interface Renewer {
  leaseId: number;
  renewalId: number;
  sections: RenewerSection[];
  userId: number;
  reminderSettingDays: number;
}

export interface RenewerSection {
  type: RenewalSectionType;
  nextSteps: string;
  reasons: { key: number; name: string }[];
}

export interface RenewerRequest {
  renewalId: number;
  type: RenewalSectionType | "0";
  notes: string;
  reasons: number[];
  snoozedDays: number;
  propertyId: number;
  leaseId: number;
  userId: number;
}
