import moment, { Moment } from "moment-timezone";

const convertUTCToLocalTime = (
  date: Date | string,
  timezone?: string | null
): Moment => {
  if (timezone) {
    return moment.utc(date).tz(timezone);
  }
  return moment.utc(date).local();
};

export default convertUTCToLocalTime;
