import { Button } from "../Button";
import { Modal } from "../Dialog";
import { Spinner } from "../Spinner";

const AgreeToTerms = ({
  open,
  onClose,
  isLoading,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  onConfirm: () => void;
}) => {
  return (
    <Modal open={open} onClose={onClose} title="Agree to terms">
      <div className="mt-4">
        <div>
          The following Terms and Conditions apply if you are enrolled in
          AutoPay. Please read these Terms and Conditions carefully.
          <br />
          <br />
          I hereby authorize Livly, on a recurring basis, to (1) automatically
          charge the debit or credit account I have specified, or (2)
          automatically debit the checking or savings account I have specified
          for payments due on my account. I understand that Livly processes
          these transactions on behalf of other parties, such as, for example,
          my landlord. I understand that this authorization to make payments by
          automatic recurring charges or debits is entirely optional and is not
          required to obtain or maintain my account with Livly.
          <br />
          <br />
          I understand that Livly will initiate transfers or charges pursuant to
          this authorization, the total of which shall not exceed either (1) the
          full amount shown on my monthly rent balance, plus service fees, or
          (2) a specific amount as designated by me, plus service fees. I
          understand that the option to designate a payment different than my
          monthly rent balance is determined by my landlord, and as such may not
          be available to me. I authorize Livly to initiate transfers or charges
          each month on either the date my rent balance is due, or the date I
          choose to have my specified amount charged or transferred. I
          understand that designating an automatic payment amount that is
          different than my monthly rent balance may result in the underpayment
          or overpayment of my account. I understand and agree that I am solely
          responsible for the maintenance of my account balance, and Livly will
          have no responsibility for any such underpayment or overpayment on my
          account. The origination of ACH transactions to my checking or savings
          account shall comply with the provisions of U.S. law. I understand
          that I am solely responsible for complying with any terms or
          conditions established by my banking or credit institution.
          <br />
          <br />
          Livly may discontinue processing recurring charges if Livly is unable
          to secure funds from my debit or credit card or from my bank account
          for the payments I have authorized due, but not limited to, (1)
          insufficient or uncollected funds in the designated account, or (2)
          insufficient or inaccurate information I provided to Livly. If
          applicable, Livly may undertake or assist in collection action,
          including application of returned check fees to the extent permitted
          by law. All such fees may be collected electronically. I authorize
          Livly to charge any debit cards, credit cards, or bank accounts
          provided by me at any time until all such delinquent amounts owed are
          recovered in full, as permitted by applicable law. I agree that Livly
          may obtain updated information from banks, card networks, issuers or
          other third-party sources. I understand that Livly may incur overdraft
          fees from my financial institution. IF I PAY MY BALANCE AFTER IT IS
          DUE, MY LIVLY ACCOUNT MAY INCUR LATE FEES AS SET FORTH IN MY CONTRACT
          AND/OR IN THE LIVLY TERMS AND CONDITIONS.
          <br />
          <br />
          I understand and agree that I am responsible for providing Livly with
          accurate payment account information. If such information changes at
          any time in the future, I will promptly notify Livly of any such
          changes. I agree that I shall contact Livly immediately if I think
          that any of the following has occurred: (1) my account was accessed
          without my authorization; (2) a transaction occurred that I did not
          authorize; or (3) a transaction was not processed correctly.
          <br />
          <br />
          I understand that I may access my Livly account up to one (1) day
          prior to my next payment due date in order to request that recurring
          automatic payments be changed or discontinued.  Livly will not
          initiate further transfers or charges after I have discontinued this
          automatic payment function and Livly has had a reasonable period of
          time to process my request.  I also understand that I am not waiving
          my rights under the Electronic Funds Transfer Act to stop payments by
          directly contacting the financial institution where the checking,
          savings, or credit account I designated is maintained, and that my
          exercise of such rights does not constitute a breach of this
          authorization.
          <br />
          <br />
          By accepting these Terms and Conditions, I am consenting to their
          delivery in this electronic form.  I understand that I may download a
          copy of these Terms and Conditions by visiting{" "}
          <a
            href="https://www.livly.io/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.livly.io/terms-of-service
          </a>
          . I also understand that I have the option to request these Terms and
          Conditions in paper form, and that I may withdraw my consent to
          electronic delivery at any time.  All requests can be made by
          contacting <a href="mailto:support@livly.io">support@livly.io</a>
        </div>
        <div className="flex pt-4 mt-4 border-t border-gray-200 gap-2">
          <Button
            className="w-full"
            color="default"
            onClick={() => onClose && !isLoading && onClose()}
            disabled={isLoading}
            data-cy="rent.auto-pay-terms-disagree-button"
          >
            Disagree
          </Button>
          <Button
            className="flex items-center w-full gap-2"
            color="primary"
            onClick={onConfirm}
            disabled={isLoading}
            data-cy="rent.auto-pay-terms-agree-button"
          >
            {isLoading && <Spinner />}
            Agree
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const DisableAutoPay = ({
  open,
  isLoading,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}) => {
  return (
    <Modal open={open} onClose={onClose} title="Disable Autopay">
      <div className="mt-4">
        <p>Are you sure you want to turn off autopay?</p>
        <div className="flex justify-end mt-4 gap-4">
          <Button onClick={onClose} color="default">
            No
          </Button>
          <Button
            onClick={onConfirm}
            disabled={isLoading}
            color="primary"
            className="flex items-center gap-2"
          >
            {isLoading && <Spinner />}
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { AgreeToTerms, DisableAutoPay };
