import useLivlyUser from "@/context/UserProvider";
import PollTask from "@/routes/polls/PollTask";
import { useLeaseTasks } from "./Tasks";
import { TaskAlertType } from "@/types/User";
import ResidentSentimentTask from "./ResidentSentimentTask";

export default function ResidentTasks() {
  const { user } = useLivlyUser();
  const { data } = useLeaseTasks(user.propertyUnitLeaseId);

  const pollTask = data?.find(
    (task) => task.alertType === TaskAlertType.ResidentPoll
  );
  const residentSentimentTask = data?.find(
    (task) => task.alertType === TaskAlertType.ResidentSentiment
  );
  const feedbackTask = data?.find(
    (task) => task.alertType === TaskAlertType.Feedback
  );

  if (pollTask) {
    return <PollTask leaseId={user.propertyUnitLeaseId} task={pollTask} />;
  }

  if (feedbackTask) {
    return (
      <ResidentSentimentTask
        leaseId={user.propertyUnitLeaseId}
        task={feedbackTask}
      />
    );
  }

  if (residentSentimentTask) {
    return (
      <ResidentSentimentTask
        leaseId={user.propertyUnitLeaseId}
        task={residentSentimentTask}
      />
    );
  }

  return null;
}
