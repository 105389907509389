import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import classNames from "classnames";
import moment from "moment-timezone";
import numeral from "numeral";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useRevalidator,
} from "react-router-dom";
import Alert from "../../components/Alert";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Dialog";
import { LayoutBody, LayoutHeader } from "../../components/Layout";
import { PaymentStartStop } from "../../components/Rent/RentAutoPayDates";
import { OtherPayees } from "../../components/Rent/RentAutoPayPayees";
import { PaymentDate } from "../../components/Rent/RentAutoPayPaymentDate";
import {
  AgreeToTerms,
  DisableAutoPay,
} from "../../components/Rent/RentAutoPayTerms";
import { SelectPaymentAccount } from "../../components/SelectPaymentAccount";
import { Spinner } from "../../components/Spinner";
import { RentContext } from "../../context/RentProvider";
import useDebounce from "../../hooks/useDebounce";
import useGetRentAutoPaySettings from "../../hooks/useGetRentAutoPaySettings";
import useServiceFee from "../../hooks/useGetServiceFee";
import { BaseLivlyApiResponse } from "../../types/Base";
import { ServiceTypeEnum } from "../../types/Building";
import { FeeData } from "../../types/Cart";
import { RentAutoPay, RentBalanceTypeEnum } from "../../types/Rent";
import { PaymentAccount, User } from "../../types/User";
import {
  trackActivateAutopay,
  trackChangeAutopayPaymentAccount,
  trackDeactivateAutopay,
  trackViewAutopayPage,
} from "../../utils/analytics";
import { BASE_API_URL } from "../../utils/constants";
import formatCurrency from "../../utils/formatCurrency";
import isCurrency from "../../utils/isCurrency";
import useLivlyUser from "../../context/UserProvider";
import useGetPaymentAccounts from "@/hooks/useGetPaymentAccounts";

const DWOLLA_LIMIT = 10000;
const currencyFormat = "$0,0[.]00";
interface ErrorResponse {
  data?: { Error: Record<string, string>; Message: string };
}

export const postAutoPaySettings = async (
  userId: number,
  leaseId: number,
  data: RentAutoPay
) => {
  const result = await axios.post<BaseLivlyApiResponse<RentAutoPay>>(
    `${BASE_API_URL}/livly/payment/${leaseId}/rentAutoPay/${userId}`,
    data
  );

  return result.data.Data;
};

export const deleteAutoPaySettings = async (
  userId: number,
  leaseId: number,
  id: number
) => {
  const result = await axios.delete(
    `${BASE_API_URL}/livly/payment/${leaseId}/rentAutoPay/${userId}/${id}`
  );

  return result.data.Data;
};

function RentAutoPayPageContainer() {
  const params = useParams<{ leaseId: string; userId: string }>();
  const navigate = useNavigate();
  const { data: rentAutoPaySettings } = useGetRentAutoPaySettings(
    params.leaseId!,
    params.userId!
  );
  const { data: paymentAccounts = [] } = useGetPaymentAccounts();
  const { user } = useLivlyUser();
  const revalidator = useRevalidator();
  const [showTerms, setShowTerms] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const defaultPaymentAccount = paymentAccounts.find((pa) => pa.isDefault);
  const selectedPaymentAccount = paymentAccounts.find(
    (pa) => pa.id === rentAutoPaySettings?.paymentAccountId
  );
  const paymentAccount = selectedPaymentAccount || defaultPaymentAccount;
  const [settings, setSettings] = useState<RentAutoPay>();

  const { mutate, isLoading: isSaving } = useMutation(
    (data: RentAutoPay) =>
      postAutoPaySettings(user.userId, user.propertyUnitLeaseId, data),
    {
      onSuccess: () => {
        revalidator.revalidate();
      },
    }
  );

  const { mutate: disableAutoPay, isLoading: isLoadingAutoPay } = useMutation(
    (id: number) =>
      deleteAutoPaySettings(user.userId, user.propertyUnitLeaseId, id),
    {
      onSuccess: () => {
        revalidator.revalidate();
      },
    }
  );

  const save = () => {
    if (!settings) {
      return;
    }

    const newSettings: RentAutoPay = {
      ...settings,
      fixedAmount: settings.isFullStatementBalance
        ? null
        : settings.fixedAmount,
    };

    mutate(newSettings, {
      onSuccess: () => {
        if (paymentAccount) {
          trackActivateAutopay(paymentAccount);
        }

        setShowTerms(false);
        navigate(
          `/lease/${user.propertyUnitLeaseId}/rent/${user.userId}/livly`
        );
      },
      onError: (data) => {
        setShowTerms(false);

        const error = data as ErrorResponse;
        setError(error.data?.Message ?? "There was an error");
        setShowError(true);
      },
    });
  };

  const disable = () => {
    if (!settings || settings.id == null) {
      return;
    }

    disableAutoPay(settings?.id, {
      onSuccess: () => {
        trackDeactivateAutopay();
        setShowDisable(false);
        navigate(
          `/lease/${user.propertyUnitLeaseId}/rent/${user.userId}/livly`
        );
      },
    });
  };

  useEffect(() => {
    if (rentAutoPaySettings) {
      setSettings({
        ...rentAutoPaySettings,
        paymentAccountId:
          rentAutoPaySettings?.paymentAccountId ?? paymentAccount?.id ?? null,
      });
    }
  }, [rentAutoPaySettings]);

  useEffect(() => {
    if (settings) {
      trackViewAutopayPage(settings.id !== null);
    }
  }, [settings]);

  const currentlySelectedPaymentMethod = paymentAccounts.find(
    (pa) => pa.id === settings?.paymentAccountId
  );

  if (settings) {
    return (
      <>
        <RentAutoPayPage
          selectedPaymentAccount={currentlySelectedPaymentMethod}
          isSaving={isSaving}
          settings={settings}
          updateSettings={setSettings}
          user={user}
          showTerms={() => setShowTerms(true)}
          onDisableAutopay={() => setShowDisable(true)}
          paymentAccounts={paymentAccounts}
        />
        <AgreeToTerms
          open={showTerms}
          onClose={() => setShowTerms(false)}
          isLoading={isSaving}
          onConfirm={save}
        />
        <DisableAutoPay
          open={showDisable}
          isLoading={isLoadingAutoPay}
          onClose={() => setShowDisable(false)}
          onConfirm={disable}
        />
        <ErrorDialog
          error={error}
          open={showError}
          onClose={() => {
            setShowError(false);
            setError("");
          }}
        />
      </>
    );
  }

  return (
    <div className="flex items-center justify-center flex-1 mt-8">
      <Spinner color="livly" size="xl" />
    </div>
  );
}

function RentAutoPayPage({
  selectedPaymentAccount,
  isSaving,
  settings,
  updateSettings,
  user,
  showTerms,
  onDisableAutopay,
  paymentAccounts,
}: {
  selectedPaymentAccount: PaymentAccount | undefined;
  isSaving: boolean;
  settings: RentAutoPay;
  updateSettings: (settings: RentAutoPay) => void;
  user: User;
  showTerms: () => void;
  onDisableAutopay: () => void;
  paymentAccounts: PaymentAccount[];
}) {
  const isNativeDevice = localStorage.getItem("mobile") === "true";
  const { rentSettings } = useOutletContext() as RentContext;

  const isFreeForm =
    rentSettings?.rentBalanceType === RentBalanceTypeEnum.FreeForm;
  const isAutoPayActive = settings.id != null;
  const paymentAmountCurrency = numeral(settings.fixedAmount).value() ?? 0;
  const amountError =
    (settings.fixedAmount ?? 0) > 0 &&
    !isCurrency(settings.fixedAmount?.toString() ?? "");
  const isMaxLimitError =
    rentSettings?.autoPayMaxAmountAllowed != null &&
    paymentAmountCurrency > rentSettings?.autoPayMaxAmountAllowed;
  const isDwollaLimitError =
    selectedPaymentAccount?.paymentType === "ACH" &&
    paymentAmountCurrency > DWOLLA_LIMIT;

  const isStartAfterEndError =
    settings.startDate != null &&
    settings.endDate != null &&
    moment(settings.startDate).isSameOrAfter(settings.endDate);

  const isStartError =
    settings.startDate != null &&
    moment().startOf("day").isSameOrAfter(settings.startDate);

  const isEndError =
    settings.endDate != null &&
    moment(user.leaseEndDate).add(-31, "days").isBefore(settings.endDate);
  let isSaveDisabled = false;

  if (isFreeForm) {
    isSaveDisabled =
      isSaving ||
      settings.fixedAmount == null ||
      settings.fixedAmount === 0 ||
      amountError ||
      isDwollaLimitError ||
      isMaxLimitError ||
      isStartAfterEndError ||
      isStartError ||
      (isEndError && !settings.overrideEndDateRestriction) ||
      settings.paymentAccountId == null ||
      settings.startDate == null ||
      settings.endDate == null ||
      settings.autoPayDay == null;
  } else {
    isSaveDisabled =
      isSaving ||
      isMaxLimitError ||
      settings.paymentAccountId == null ||
      settings.isFullStatementBalance === null ||
      (settings.isFullStatementBalance === false &&
        (settings.fixedAmount == null ||
          settings.fixedAmount === 0 ||
          amountError ||
          isDwollaLimitError ||
          isMaxLimitError));
  }

  return (
    <div className="h-screen">
      {!isNativeDevice && (
        <LayoutHeader
          title="Set up Autopay"
          back={{ label: "Rent", to: `../rent/${user.userId}/livly` }}
        />
      )}
      <LayoutBody>
        {settings.id !== null && (
          <div className="flex justify-end mb-4">
            <Button onClick={onDisableAutopay} size="small" color="primary">
              Disable Autopay
            </Button>
          </div>
        )}
        {settings.message && (
          <div className="my-4">
            <Alert variant="danger" message={settings.message} />
          </div>
        )}
        <SelectPaymentAccount
          selectedPaymentAccountId={selectedPaymentAccount!.id}
          paymentAccounts={paymentAccounts.filter((pa) => pa.isActive)}
          onSelect={(id) =>
            updateSettings({ ...settings, paymentAccountId: id })
          }
          onOpenCallback={trackChangeAutopayPaymentAccount}
        />
        {isFreeForm && (
          <PaymentDate
            day={settings.autoPayDay}
            setDay={(autoPayDay) => updateSettings({ ...settings, autoPayDay })}
            daysAllowed={settings.daysAutoPayAllowed}
          />
        )}
        <PaymentAmount
          isFreeForm={isFreeForm}
          isFixedAmountAllowed={rentSettings?.fixedAmountAllowed ?? false}
          paymentAccount={selectedPaymentAccount}
          propertyId={user.propertyId}
          isMaxLimitError={isMaxLimitError}
          isDwollaLimitError={isDwollaLimitError}
          amount={settings.fixedAmount}
          setAmount={(fixedAmount) =>
            updateSettings({ ...settings, fixedAmount })
          }
          error={amountError}
          setIsFullBalance={(isFullStatementBalance) =>
            updateSettings({ ...settings, isFullStatementBalance })
          }
          isFullStatementBalance={settings.isFullStatementBalance}
        />
        {isFreeForm && (
          <PaymentStartStop
            isAutoPayActive={isAutoPayActive}
            startDate={settings.startDate}
            endDate={settings.endDate}
            setStartDate={(startDate) =>
              updateSettings({
                ...settings,
                startDate: startDate ? startDate.toISOString() : "",
              })
            }
            setEndDate={(endDate) =>
              updateSettings({
                ...settings,
                endDate: endDate ? endDate.toISOString() : "",
              })
            }
            day={settings.autoPayDay}
            isStartAfterEndError={isStartAfterEndError}
            isStartError={isStartError}
            isEndError={isEndError}
            agreedToEndDateOverride={settings.overrideEndDateRestriction}
            setAgreedToEndDateOverride={(bool) =>
              updateSettings({
                ...settings,
                overrideEndDateRestriction: bool,
              })
            }
          />
        )}
        <OtherPayees payees={settings.roommateAutoPayments} />
        <div className="fixed bottom-0 left-0 right-0 flex flex-col items-stretch m-4 bg-white border border-gray-100 rounded-lg shadow-lg md:flex-row md:justify-between md:items-baseline md:left-64 drop-shadow-lg">
          <div className="flex flex-col items-center justify-between w-full p-4 border-t border-gray-200 md:flex-row md:border-none">
            <div className="flex flex-col items-center justify-center mr-4 md:items-baseline">
              <p className="text-2xl">
                {settings.nextBillingDate
                  ? moment(settings.nextBillingDate).format("MMMM D, YYYY")
                  : ""}
              </p>
              <p className="text-xs font-light md:mr-2">next billing date</p>
            </div>
            <Button
              color="secondary"
              onClick={showTerms}
              disabled={isSaveDisabled}
              className="flex items-center w-full gap-2 mt-4 md:w-auto md:mt-0"
            >
              {isSaving && <Spinner />}
              {settings.id !== null ? "Update changes" : "Activate Autopay"}
            </Button>
          </div>
        </div>
      </LayoutBody>
    </div>
  );
}

export { RentAutoPayPageContainer as RentAutoPayPage };

function PaymentAmount({
  paymentAccount,
  propertyId,
  amount,
  setAmount,
  isFreeForm,
  isFullStatementBalance,
  setIsFullBalance,
  isMaxLimitError,
  isDwollaLimitError,
  isFixedAmountAllowed,
  error,
}: {
  isFreeForm: boolean;
  isFixedAmountAllowed: boolean;
  isFullStatementBalance: boolean | null;
  setIsFullBalance: (isFullBalance: boolean) => void;
  propertyId: number;
  paymentAccount?: PaymentAccount;
  isMaxLimitError: boolean;
  isDwollaLimitError: boolean;
  amount: number | null;
  setAmount: (amount: number) => void;
  error: boolean;
}) {
  const { t } = useTranslation();
  const { feeData: fullBalanceFeeData, loading: isFullBalanceFeeDataLoading } =
    useServiceFee(propertyId, ServiceTypeEnum.Rent, paymentAccount, 100);

  let debouncedPaymentAmount = useDebounce(amount ? String(amount) : "", 500);
  const { feeData, loading } = useServiceFee(
    propertyId,
    ServiceTypeEnum.Rent,
    paymentAccount,
    Number(debouncedPaymentAmount.replace(/[^\d.-]/g, ""))
  );

  const fullBalanceFeeDescription = getFullBalanceFeeDescription(
    paymentAccount?.paymentType,
    fullBalanceFeeData
  );

  return (
    <div className="py-4">
      <p className="text-sm text-gray-500 lowercase">
        {t("make-payment.select-payment-amount")}
      </p>
      {!isFreeForm && (
        <label
          className={classNames(
            "py-4 border-b border-gray-200 flex justify-between items-center gap-4"
          )}
          onClick={() => {
            setIsFullBalance(true);
          }}
        >
          <div>
            <p className="text-xl font-light">Statement balance</p>

            <p className="mt-1 text-gray-500 text-sm font-light">
              {isFullBalanceFeeDataLoading
                ? "Calculating fee"
                : fullBalanceFeeDescription}
            </p>
          </div>
          {isFullStatementBalance && (
            <FontAwesomeIcon icon="check" className="text-red-400" />
          )}
        </label>
      )}
      {(isFreeForm || isFixedAmountAllowed) && (
        <label
          className="flex items-center justify-between gap-4 py-4 border-b border-gray-200"
          onClick={() => setIsFullBalance(false)}
          htmlFor="rent-payment-amount"
        >
          <div className="flex-1">
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-0 pointer-events-none">
                <span
                  className={classNames("text-3xl", {
                    "text-red-400": error,
                  })}
                >
                  $
                </span>
              </div>
              <CurrencyInput
                name="rent-payment-amount"
                id="rent-payment-amount"
                className={classNames(
                  "peer border-none w-full outline-none focus:ring-0 pl-6 pr-6 text-3xl bg-white",
                  { "text-red-400": error }
                )}
                decimalsLimit={2}
                allowNegativeValue={false}
                placeholder="0.00"
                defaultValue={amount ?? ""}
                onValueChange={(value) => setAmount(Number(value ?? 0))}
              />
            </div>
            <p className="mt-1 text-xs text-gray-500">
              {loading
                ? "Calculating fee"
                : amount != null && amount > 0 && feeData?.calculatedFeeAmount
                ? `${t("general.processing-fee")}: ${numeral(
                    feeData.calculatedFeeAmount
                  ).format(currencyFormat)}`
                : null}
            </p>

            <p className="mt-1 text-gray-500">
              {isFixedAmountAllowed ? "Fixed Amount" : "Recurring Amount"}
            </p>
          </div>
          {!isFullStatementBalance && (
            <FontAwesomeIcon icon="check" className="text-red-400" />
          )}
        </label>
      )}
      {/* {!isFreeForm && (
        <>
          <label
            style={{ cursor: "pointer" }}
            onClick={() => setIsFullBalance(true)}
          >
            <div>
              <p>Statement Balance</p>
              <p>
                {isFullBalanceFeeDataLoading
                  ? "Calculating fee"
                  : fullBalanceFeeDescription}
              </p>
            </div>
            {isFullStatementBalance === true && (
              <FontAwesomeIcon icon="check" className="text-red-400" />
            )}
          </label>
        </>
      )}
      {(isFreeForm || isFixedAmountAllowed) && (
        <>
          <label
            htmlFor="fixed-amount"
            className="flex items-center justify-between"
            onClick={() => setIsFullBalance(false)}
          >
            <div>
              <div className="relative mt-2">
                <div className="absolute inset-y-0 left-0 flex items-center pl-0 pointer-events-none">
                  <span
                    className={classNames("text-3xl", {
                      "text-red-400": error,
                    })}
                  >
                    $
                  </span>
                </div>
                <input
                  name="fixed-amount"
                  id="fixed-amount"
                  className={classNames(
                    "peer border-none w-full outline-none focus:ring-0 pl-6 pr-6 text-3xl bg-white",
                    { "text-red-400": error }
                  )}
                  value={amount || ""}
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
              </div>
              <p className="mt-1 text-xs text-gray-500">
                {loading
                  ? "Calculating fee"
                  : amount != null && amount > 0 && feeData?.calculatedFeeAmount
                  ? `${t("general.processing-fee")}: ${numeral(
                      feeData.calculatedFeeAmount
                    ).format(currencyFormat)}`
                  : null}
              </p>

              <p className="mt-1 text-gray-500">
                {isFixedAmountAllowed ? "Fixed Amount" : "Recurring Amount"}
              </p>
            </div>
            {isFullStatementBalance === false && !isFreeForm && (
              <FontAwesomeIcon icon="check" className="text-red-400" />
            )}
          </label>

          
        </>
      )} */}
      {isMaxLimitError && (
        <div className="my-4">
          <Alert
            variant="danger"
            message="You have reached your maximum auto payment limit."
          />
        </div>
      )}
      {isDwollaLimitError && (
        <div className="my-4">
          <Alert
            variant="danger"
            message="ACH transactions are limited to $10,000 per week."
          />
        </div>
      )}
      {error && (
        <div className="my-4">
          <Alert variant="danger" message="Please enter a valid amount." />
        </div>
      )}
    </div>
  );
}

function ErrorDialog({
  open,
  onClose,
  error,
}: {
  open: boolean;
  onClose: (open: boolean) => void;
  error: string;
}) {
  return (
    <Modal open={open} onClose={onClose} title="Sorry">
      <div className="mt-4">
        <Alert variant="danger" message={error} />
        <div className="flex justify-end mt-4">
          <Button onClick={() => onClose(false)}>Ok</Button>
        </div>
      </div>
    </Modal>
  );
}

const getFullBalanceFeeDescription = (
  paymentType: "ACH" | "Credit Card" | undefined,
  feeData: FeeData | null
) => {
  if (paymentType == null || feeData == null) {
    return "";
  }

  const amount = feeData.value;
  if (paymentType === "ACH") {
    if (feeData.feeType === "Percentage") {
      return `${amount}% processing fee will be applied`;
    } else if (feeData.feeType === "FixedAmount")
      return `${formatCurrency(amount)} processing fee will be applied`;
  } else if (paymentType === "Credit Card") {
    if (feeData.feeType === "Percentage") {
      return `${amount}% processing fee will be applied`;
    }
  }
};
