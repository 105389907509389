import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import moment from "moment-timezone";
import { useState } from "react";
import { Button } from "../Button";
import { Modal } from "../Dialog";

const PaymentDate = ({
  day,
  setDay,
  daysAllowed,
}: {
  day: number | null;
  setDay: (day: number) => void;
  daysAllowed: number[];
}) => {
  const [open, setOpen] = useState(false);
  const [newDay, setNewDay] = useState(day);

  const onOpenCalendar = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    if (!newDay) {
      return;
    }

    setDay(newDay);
    onClose();
  };

  return (
    <>
      <div className="border-gray-200 border-y">
        {day ? (
          <div className="flex items-center justify-between py-4">
            <div>
              <h3 className="text-sm">Payment date</h3>
              <p>
                {moment()
                  .month(0)
                  .date(day ?? 1)
                  .format("Do")}{" "}
                of the month
              </p>
            </div>
            <button onClick={onOpenCalendar}>
              <FontAwesomeIcon
                icon={["far", "calendar-day"]}
                className="text-red-400"
              />
            </button>
          </div>
        ) : (
          <button
            onClick={onOpenCalendar}
            className="flex items-center justify-between w-full py-4 text-red-400"
          >
            <p>Choose payment day</p>
            <FontAwesomeIcon icon={["far", "calendar-day"]} />
          </button>
        )}
      </div>
      <Modal open={open} onClose={onClose} title="Select Day">
        <div className="mt-6">
          <div className="items-center justify-center mb-6 grid grid-cols-7 gap-3">
            {Array.from(Array(31)).map((_, i) => {
              const value = i + 1;
              const isDisabled = !daysAllowed.includes(value);

              return (
                <div
                  className={classNames(
                    "flex justify-center items-center h-9 w-9 rounded-full cursor-pointer hover:bg-red-400",
                    {
                      "cursor-initial text-gray-300 hover:bg-transparent":
                        isDisabled,
                      "bg-red-400 text-white": newDay === value,
                    }
                  )}
                  key={i}
                  onClick={() => {
                    if (isDisabled) {
                      return;
                    }

                    setNewDay(value);
                  }}
                >
                  {value}
                </div>
              );
            })}
          </div>
          <p className="text-sm">
            If the month doesn’t have 31 days, it will be default to the last
            day of the month
          </p>
          <div className="flex items-center justify-between pt-6 mt-6 border-t border-gray-200">
            <div>
              {newDay && (
                <>
                  <p className="text-sm">Payment will be scheduled for the</p>
                  <p>
                    {moment()
                      .month(0)
                      .date(newDay ?? 1)
                      .format("Do")}{" "}
                    of the month
                  </p>
                </>
              )}
            </div>
            <div>
              <Button
                color="primary"
                onClick={onConfirm}
                disabled={newDay == null}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { PaymentDate };
