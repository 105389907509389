import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import AddTicketForm from "../../components/AddTicketForm";

export default function AddMaintenanceTicketPage() {
  const params = useParams<{ leaseId: string; propertyId: string }>();

  return (
    <Layout
      title="Add Issue"
      back={{
        label: "Maintenance Tickets",
        to: `/lease/${params.leaseId}/maintenance/${params.propertyId}`,
      }}
    >
      <div className="max-w-lg mx-auto">
        <AddTicketForm />
      </div>
    </Layout>
  );
}
