import useLivlyUser from "@/context/UserProvider";
import { ReviewLocation } from "@/types/Maintenance";
import { Modal } from "./Dialog";
import { Button } from "./Button";
import { trackReviewLocationClick } from "@/utils/analytics";

export default function ThankYou({
  open,
  onClose,
  reviewLocations,
}: {
  open: boolean;
  onClose: () => void;
  reviewLocations: ReviewLocation[];
}) {
  const { user } = useLivlyUser();

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex flex-col items-center">
        <h2 className="text-lg font-bold">Thank you!</h2>
        <p className="mt-2">
          Your living experience is important to us. Thanks for your feedback.
        </p>
        <img src="/thumbs_up.png" className="h-48 py-8" />
        {reviewLocations.length > 0 && (
          <>
            <p className="mt-2">
              Seems you are having a great time living at {user.building?.name}!
              Would you like to share this review on the following site? That’ll
              be really appreciated!
            </p>
            <div className="mt-4">
              <ReviewLocationLinks reviewLocations={reviewLocations} />
            </div>
          </>
        )}
        <div className="flex justify-end items-end mt-4">
          <Button
            size="small"
            color="primary"
            onClick={onClose}
            className="w-full md:w-auto"
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function ReviewLocationLinks({
  reviewLocations,
}: {
  reviewLocations: ReviewLocation[];
}) {
  if (reviewLocations && reviewLocations.length > 0) {
    const reviewLocation = reviewLocations[0];

    return (
      <div className="py-4">
        <a
          href={reviewLocation.url}
          onClick={() => {
            trackReviewLocationClick();
          }}
          target="_blank"
          rel="noreferrer"
          className="text-red-400"
          style={{
            textDecoration: "none",
            fontSize: 16,
          }}
        >
          Leave the review on {reviewLocation.name}
        </a>
      </div>
    );
  }

  return null;
}
