import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import moment from "moment-timezone";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonWrapper } from "../../components/Button";
import Layout from "../../components/Layout";
import useGetRentSettings from "../../hooks/useGetRentSettings";
import useGetService from "../../hooks/useGetService";
import { ServiceTypeEnum } from "../../types/Building";
import { RentBalanceTypeEnum } from "../../types/Rent";
import {
  BuildingTypeEnum,
  LedgerItem,
  OnboardingStatus,
  OnboardingStatusEnum,
} from "../../types/User";
import {
  trackConfirmProfileAndLeaseInfo,
  trackUserClickMoveInSupport,
} from "../../utils/analytics";
import formatCurrency from "../../utils/formatCurrency";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import useLivlyUser from "../../context/UserProvider";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

const dateFormat = "MMM D, YYYY";

export default function ChecklistProfilePage() {
  const params = useParams<{ leaseId: string }>();
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.profile, user);
  const { updateOnboardingStatus, isLoading } = usePostService(
    user.userId,
    params.leaseId!,
    details.onboardingStatus
  );
  const isRentEnabled = useGetService(ServiceTypeEnum.Rent)?.enabled;

  const onNext = async () => {
    trackConfirmProfileAndLeaseInfo();

    if ((user.roommates ?? []).length > 0) {
      navigate("../roommates");
    } else if (!user.avatarURI) {
      navigate("../avatar");
    } else {
      const newOnboardingStatus: OnboardingStatus = {
        ...details.onboardingStatus,
        isCompleted: true,
      };
      await updateOnboardingStatus(newOnboardingStatus);

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate("..");
    }
  };

  return (
    <Layout
      title="Profile"
      back={{
        label: "Move-in Checklist",
        to: `..`,
      }}
    >
      <div className="pb-24">
        <FontAwesomeIcon
          icon={details.icon}
          className={classNames("text-3xl text-red-300")}
        />
        <h2 className={classNames("font-medium text-lg mt-2")}>
          {details.title}
        </h2>
        <p className={classNames("text-sm")}>{details.description}</p>

        <p className="mt-6 font-light">your name</p>
        <p className="mt-1 text-sm font-medium">
          {user.firstName} {user.lastName}
        </p>
        <p className="mt-6 font-light">
          where you
          {"'"}
          re living
        </p>
        <p className="mt-1 text-sm font-medium">
          {user.buildingName} {user.unit}
        </p>
        <p className="text-sm text-gray-500">
          {user.address}, {user.city}
        </p>

        {user.presentation.buildingType === BuildingTypeEnum.Apartments && (
          <>
            <p className="mt-6 font-light">your lease term</p>
            <div className="flex items-center gap-4 mt-1">
              <p className="text-sm font-medium">
                {moment(user.leaseStartDate).format(dateFormat)}
              </p>
              <FontAwesomeIcon icon="arrow-right" />
              <p className="text-sm font-medium">
                {moment(user.leaseEndDate).format(dateFormat)}
              </p>
            </div>
          </>
        )}

        {isRentEnabled && (
          <LedgerItems ledger={user.ledger ?? []} leaseId={params.leaseId!} />
        )}

        <div className="mt-6">
          <p className="text-sm">
            Something incorrect?{" "}
            <a
              className="text-gray-500 underline"
              onClick={trackUserClickMoveInSupport}
              href="mailto:support@livly.io"
            >
              Contact support
            </a>
            .
          </p>
        </div>
      </div>
      <ButtonWrapper>
        <Button
          onClick={onNext}
          className="w-full md:w-auto"
          color="secondary"
          disabled={isLoading}
        >
          Looks good! Next
        </Button>
      </ButtonWrapper>
    </Layout>
  );
}

function LedgerItems({
  ledger,
  leaseId,
}: {
  ledger: LedgerItem[];
  leaseId: string;
}) {
  const { data: rentSettings } = useGetRentSettings(leaseId);

  if (rentSettings?.rentBalanceType === RentBalanceTypeEnum.FreeForm) {
    return (
      <div className="mt-6 border-t border-gray-200">
        <p className="mt-6 font-light">rent</p>
        <p className="mt-1 text-sm font-medium">
          Please reference the amount communicated to you by your property.
        </p>
      </div>
    );
  }

  const filteredLedgerItems = ledger.filter(
    (charge) =>
      charge.type === "Charge" &&
      charge.yardiId &&
      charge.yardiId.indexOf("FirstRent_") !== -1
  );

  if (filteredLedgerItems.length === 0) {
    return null;
  }

  const chargesSum = filteredLedgerItems.reduce(
    (acc, curr) => acc + (curr.amount > 0 ? curr.amount : 0),
    0
  );

  return (
    <div>
      <ul className="space-y-2">
        {filteredLedgerItems.map((ledgerItem) => (
          <li key={ledgerItem.id} className="flex justify-between">
            <p>{ledgerItem.description}</p>
            <p>{formatCurrency(ledgerItem.amount).replace(".00", "")}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}
