import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AutoPayIcon() {
  return (
    <span className={`fa-layers fa-fw text-blue-500`}>
      <FontAwesomeIcon icon={"rotate-right"} />
      <FontAwesomeIcon icon={"dollar-sign"} transform={"shrink-8"} />
    </span>
  );
}

export { AutoPayIcon };
