import { createContext, useContext, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { Dispatch, useEffect, useReducer } from "react";
import useLivlyChat from "../context/ChatProvider";
import useLivlyUser from "../context/UserProvider";
import { useQuery } from "@tanstack/react-query";
import { TicketState } from "../types/Maintenance";
import { Channel, StreamChat } from "stream-chat";

interface TicketChannelContextShape {
  messageCounts: Record<number, number>;
}

const initialState: TicketChannelContextShape = {
  messageCounts: {},
};

const TicketChannelContext =
  createContext<TicketChannelContextShape>(initialState);

function TicketChannelProvider() {
  const messageCounts = useTicketChats();

  const value = useMemo(
    () => ({
      messageCounts,
    }),
    [messageCounts]
  );

  return (
    <TicketChannelContext.Provider value={value}>
      <Outlet />
    </TicketChannelContext.Provider>
  );
}

export { TicketChannelProvider };

export default function useTicketChannels() {
  const context = useContext(TicketChannelContext);
  if (!context) {
    throw new Error(
      `useTicketChannels must be used within a TicketChannelContext`
    );
  }

  return context;
}

interface ReducerState {
  tickets: Record<number, number>;
}

type Action =
  | { type: "update_count"; ticketId: number; count: number }
  | { type: "load_channels"; tickets: Record<number, number> };

function reducer(state: ReducerState, action: Action): ReducerState {
  switch (action.type) {
    case "update_count":
      return {
        ...state,
        tickets: { ...state.tickets, [action.ticketId]: action.count },
      };
    case "load_channels":
      return {
        ...state,
        tickets: action.tickets,
      };
    default:
      return state;
  }
}

function createInitialState(): ReducerState {
  return {
    tickets: {},
  };
}

const SORT_DESC = -1;
const DEFAULT_CHANNEL_REQUEST_LIMIT = 30; // Is max according to doc https://getstream.io/chat/docs/csharp/#query_channels
const FETCH_CHANNELS_MAX_COUNT = 3 * DEFAULT_CHANNEL_REQUEST_LIMIT;

async function getChannels(
  chatClient: StreamChat | null,
  externalPropertyId: string,
  chatUserId: string
) {
  if (!chatClient) {
    return;
  }

  let allMessageCounts: NewMessagesCounter[] = [];

  let currentOffset = 0;
  while (currentOffset < FETCH_CHANNELS_MAX_COUNT) {
    const channels = await chatClient.queryChannels(
      {
        $and: [
          {
            externalPropertyId: externalPropertyId,
          },
          {
            members: { $in: [chatUserId] },
          },
        ],
      },
      {
        last_message_at: SORT_DESC,
      },
      {
        state: false,
        watch: true,
        limit: DEFAULT_CHANNEL_REQUEST_LIMIT,
        offset: currentOffset,
      }
    );
    const channelsNewMessages = getChannelsNewMessagesByTicketIds(channels);

    allMessageCounts = [...allMessageCounts, ...channelsNewMessages];

    if (channels.length < DEFAULT_CHANNEL_REQUEST_LIMIT) {
      break;
    }
    currentOffset += DEFAULT_CHANNEL_REQUEST_LIMIT;
  }

  return allMessageCounts;
}

function useTicketChats() {
  const { user } = useLivlyUser();
  const { chatClient } = useLivlyChat();
  const [state, dispatch] = useReducer(reducer, undefined, createInitialState);

  useEffect(() => {
    async function getMeChannels() {
      const channels = await getChannels(
        chatClient,
        user.propertyCode,
        user.streamUserId!
      );
      const tickets = (channels ?? []).reduce((prev, curr) => {
        prev[curr.ticketId] = curr.count;
        return prev;
      }, {} as Record<number, number>);

      dispatch({ type: "load_channels", tickets });

      chatClient?.on(
        "notification.mark_read",
        handleMessageEvent(chatClient, dispatch)
      );
      // when a message is added to a channel for clients that are not currently watching the channel
      chatClient?.on(
        "notification.message_new",
        handleMessageEvent(chatClient, dispatch)
      );
      // when a new message is added on a channel for clients watching the channel
      chatClient?.on("message.new", handleMessageEvent(chatClient, dispatch));
    }

    getMeChannels();
  }, []);

  return state.tickets;
}

export interface NewMessagesCounter {
  ticketId: number;
  status: TicketState;
  count: number;
  externalPropertyId: string;
}

const handleMessageEvent =
  (chatClient: StreamChat, dispatch: Dispatch<Action>) =>
  async (event: any) => {
    const channelId = event.channel ? event.channel.cid : event.cid;
    if (channelId) {
      const channels = await chatClient.queryChannels(
        { cid: channelId },
        {
          last_message_at: SORT_DESC,
        },
        {
          state: false,
          watch: false,
          limit: DEFAULT_CHANNEL_REQUEST_LIMIT,
        }
      );
      const channelNewMessages = getChannelsNewMessagesByTicketIds(channels)[0];

      dispatch({
        type: "update_count",
        ticketId: channelNewMessages.ticketId,
        count: channelNewMessages.count,
      });
    }
  };

function getChannelsNewMessagesByTicketIds(channels: Channel[]) {
  const channelsNewMessages: NewMessagesCounter[] = [];

  channels.forEach((channel: Channel) => {
    const ticketId = Number(channel.data?.maintenanceTicketId);
    const status = channel.data?.maintenanceTicketStatus as TicketState;
    const externalPropertyId = channel.data?.externalPropertyId as string;
    if (ticketId) {
      const count = channel.countUnread();
      channelsNewMessages.push({
        ticketId,
        status,
        count,
        externalPropertyId,
      });
    }
  });

  return channelsNewMessages;
}
