import moment from "moment-timezone";
import { RoommateAutoPayments } from "../../types/Rent";
import formatCurrency from "../../utils/formatCurrency";
import Avatar from "../Avatar";

const OtherPayees = ({ payees }: { payees: RoommateAutoPayments[] }) => {
  if (payees.length === 0) {
    return null;
  }

  return (
    <div className="mt-4">
      <h4 className="text-sm uppercase">Other autopay on this lease</h4>
      <ul>
        {payees.map((payee) => (
          <li key={payee.userId} className="flex items-center py-2">
            <Avatar
              size="xs"
              src={payee.avatarUri}
              name={`${payee.firstName} ${payee.lastName}`}
            />
            <div className="flex-1">
              <p>
                {payee.firstName} {payee.lastName}
              </p>
              <p className="text-sm text-gray-600">
                {moment().month(0).date(payee.autoPayDay).format("Do")} of each
                month
              </p>
            </div>
            <div>
              <p>{formatCurrency(payee.amount)}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { OtherPayees };
