import { Modal } from "@/components/Dialog";
import { Spinner } from "@/components/Spinner";
import useLivlyUser from "@/context/UserProvider";
import { BaseLivlyApiResponse } from "@/types/Base";
import {
  Feedback,
  FeedbackOption,
  FeedbackRequest,
  FeedbackType,
} from "@/types/Maintenance";
import { BASE_API_URL } from "@/utils/constants";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { textAreaClass } from "@/components/Form";
import { RatingIcon } from "@/components/RatingIcon";
import { Button } from "@/components/Button";
import { useEffect, useState } from "react";
import { ResidentSentimentModal } from "@/components/ResidentSentimentTask";
import toast from "react-hot-toast";

async function getFeedbackItem(id: string) {
  const result = await axios.get<BaseLivlyApiResponse<Feedback>>(
    `${BASE_API_URL}/resident/feedback/${id}`
  );

  return result.data.Data;
}

async function updateFeedbackItem(feedback: FeedbackRequest) {
  const result = await axios.put<BaseLivlyApiResponse<Feedback>>(
    `${BASE_API_URL}/resident/feedback/${feedback.feedbackId}`,
    feedback
  );

  return result.data.Data;
}

export default function EditFeedbackAndReviewsPage() {
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams<{ reviewId: string }>();
  const [feedback, setFeedback] = useState<FeedbackRequest | null>(null);
  const updateFeedkbackItemMutation = useMutation(
    (updatedFeedback: FeedbackRequest) => updateFeedbackItem(updatedFeedback)
  );

  const { data, isLoading } = useQuery({
    queryKey: ["my-feedback", "item", params.reviewId],
    queryFn: () => getFeedbackItem(params.reviewId!),
  });

  useEffect(() => {
    if (data) {
      setFeedback({
        feedbackId: data.residentFeedbackId,
        rating: data.rating,
        comment: data.comment,
        feedbackItems: data.feedbackItems,
      });
    }
  }, [data]);

  const onSetFeedback = (newFeedback: Partial<FeedbackRequest>) => {
    if (feedback) {
      setFeedback({ ...feedback, ...newFeedback });
    }
  };

  const handleSubmit = (feedback: FeedbackRequest) => {
    updateFeedkbackItemMutation.mutate(feedback, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["reviews"] });
        navigate("..");
        toast.success("Your feedback updated successfully!");
      },
    });
  };

  const title = getTitle(data?.feedbackType!, user.buildingName);

  if (isLoading || !feedback) {
    <Modal open onClose={() => navigate("..")}>
      <h2>{title}</h2>
      <Spinner />
    </Modal>;
  }

  if (data?.feedbackType === "MaintenanceTicket") {
    return (
      <FeedbackModal
        title={title}
        feedback={feedback}
        onSetFeedback={onSetFeedback}
        isSubmitting={updateFeedkbackItemMutation.isLoading}
        isLoading={isLoading}
        options={data?.options}
        handleSubmit={handleSubmit}
      />
    );
  }

  return (
    <ResidentSentimentModal
      feedback={feedback}
      onSetFeedback={onSetFeedback}
      isLoading={isLoading}
      isModalOpen
      onSkip={() => {
        if (feedback) {
          handleSubmit({ ...feedback, comment: "" });
        }
      }}
      onSubmit={() => {
        if (feedback) {
          handleSubmit(feedback);
        }
      }}
      onClose={() => navigate("..")}
      showThankYou={false}
      reviewLocations={[]}
      onCloseThankYou={() => {}}
      feedbackType="Default"
    />
  );
}

function FeedbackModal({
  options,
  feedback,
  onSetFeedback,
  title,
  isLoading,
  isSubmitting,
  handleSubmit,
}: {
  options:
    | {
        positive: FeedbackOption[];
        negative: FeedbackOption[];
      }
    | undefined;
  feedback: FeedbackRequest | null;
  onSetFeedback: (newFeedback: Partial<Feedback>) => void;
  title: string;
  isLoading: boolean;
  isSubmitting: boolean;
  handleSubmit: (feedback: FeedbackRequest) => void;
}) {
  const navigate = useNavigate();

  const isNegativeRating = [0, 1].includes(Number(feedback?.rating));
  const feedbackOptions = isNegativeRating
    ? options?.negative
    : options?.positive;

  return (
    <Modal open onClose={() => navigate("..")}>
      <h2>{title}</h2>

      <div className="mt-4">
        <div className="grid grid-cols-4 gap-1">
          {[0, 1, 2, 3].map((rating) => (
            <label
              className="flex items-center justify-center"
              key={rating}
              onClick={() => onSetFeedback({ rating, feedbackItems: [] })}
            >
              <input
                id="rating"
                name="rating"
                type="radio"
                value={rating.toString()}
                className="hidden w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
              />
              <RatingIcon
                rating={rating}
                isSelected={rating === Number(feedback?.rating)}
              />
              <span className="hidden ml-4" aria-label={rating.toString()}>
                {rating}
              </span>
            </label>
          ))}
        </div>
        <AnimatePresence mode="wait">
          {feedback?.rating != null ? (
            <motion.div
              className="pt-6 mt-6 border-t border-gray-200"
              initial={{
                height: 0,
              }}
              animate={{
                height: "auto",
                opacity: 1,
                transition: {
                  height: {
                    duration: 0.4,
                  },
                },
              }}
              key="test"
            >
              <p className="text-lg font-medium">
                {isNegativeRating
                  ? "How can your experience be improved?"
                  : " Great! Tell us what can be improved?"}
              </p>
              <p className="mt-2">
                We will pass this information along to your property manager.
                Select all that apply.
              </p>
              <ul className="mt-4">
                {feedbackOptions?.map((option) => (
                  <li key={option.residentFeedbackItemOptionTypeId}>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        name="feedbackItems"
                        checked={feedback?.feedbackItems.includes(
                          option.residentFeedbackItemOptionTypeId
                        )}
                        onChange={() => {
                          if (
                            feedback?.feedbackItems.includes(
                              option.residentFeedbackItemOptionTypeId
                            )
                          ) {
                            onSetFeedback({
                              feedbackItems: feedback?.feedbackItems.filter(
                                (item) =>
                                  item !==
                                  option.residentFeedbackItemOptionTypeId
                              ),
                            });
                          } else {
                            onSetFeedback({
                              feedbackItems: [
                                ...feedback?.feedbackItems,
                                option.residentFeedbackItemOptionTypeId,
                              ],
                            });
                          }
                        }}
                      />
                      <span>{option.description}</span>
                    </label>
                  </li>
                ))}
              </ul>
              <div className="mt-4">
                <label htmlFor="comment">
                  <p>Leave us a comment</p>
                  <textarea
                    id="comment"
                    name="comment"
                    className={textAreaClass}
                    rows={4}
                    value={feedback?.comment}
                    onChange={(e) => onSetFeedback({ comment: e.target.value })}
                  />
                </label>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>

        <div className="flex justify-end mt-4">
          <Button
            size="small"
            color="primary"
            onClick={() => {
              if (feedback) {
                handleSubmit(feedback);
              }
            }}
            disabled={isLoading || isSubmitting || !feedback?.rating}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function getTitle(feedbackType: FeedbackType, buildingName: string) {
  switch (feedbackType) {
    case "AmenityBooking":
      return `How’s your experience with this amenity booking?`;
    case "Event":
      return `How’s your experience with this event?`;
    case "MaintenanceTicket":
      return `How’s your experience with this maintenance service?`;
    case "Property":
      return `How’s your experience with ${buildingName}?`;
    default:
      return `How has your experience been?`;
  }
}
